import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import {
  Unstable_Grid2 as Grid,
  Typography
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GOOGLE_MAPS_API_KEY = 'AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const LocalizationInputs = ({ json, formType, oldValue, isCreate, isEdit, isShow, values, fieldErrors }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState('');
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const formState = state[formType];

  /* const isEdit = state.general.isEdit
  const isShow = state.general.isShow */

    // Start google locations autocomplete
    const [addressValue, setAddressValue] = useState('');
    const [postalCodeValue, setPostalCodeValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [provinceValue, setProvinceValue] = useState('');
    const [regionValue, setRegionValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const loaded = React.useRef(false);
    const cityObj = {};
  
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }
  
      loaded.current = true;
    }
  
    const fetch = React.useMemo(
      () =>
        debounce((request, callback) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        }, 400),
      [],
    );
  
    useEffect(() => {
      let active = true;
  
      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }
  
      if (inputValue === '') {
        setOptions(regionValue ? [regionValue] : []);
        return undefined;
      }
  
      fetch({ input: inputValue, types: ["address"], componentRestrictions: {country: 'it'}, fields: ["address_components", "formatted_address", "geometry"], }, (results) => {
        if (active) {
          let newOptions = [];
  
          if (regionValue) {
            newOptions = [regionValue];
          }
  
          if (results) {
            const newOptions = [];
          
            const requests = results.map((result) => {
              return new Promise((resolve) => {
                const request = {
                  placeId: result.place_id,
                  fields: ["address_components", "formatted_address"],
                };
          
                const service = new window.google.maps.places.PlacesService(document.createElement("div"));
          
                service.getDetails(request, (place, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // Ottieni i componenti dell'indirizzo dal place
                    const addressComponents = place?.address_components;
  
                    // Ottieni il componente dell'indirizzo corrispondente al numero civico
                    const streetNumberComponent = addressComponents.find(
                      (component) => component.types.includes("street_number")
                    );
       
                    // Ottieni il componente dell'indirizzo corrispondente alla regione
                    const regionComponent = addressComponents.find(
                      (component) => component.types.includes("administrative_area_level_1")
                    );
          
                    // Ottieni il componente dell'indirizzo corrispondente alla provincia
                    const provinceComponent = addressComponents.find(
                      (component) => component.types.includes("administrative_area_level_2")
                    );
          
                    // Ottieni il componente dell'indirizzo corrispondente alla città
                    const cityComponent = addressComponents.find(
                      (component) => component.types.includes("locality")
                    );
          
                    // Ottieni il componente dell'indirizzo corrispondente al codice postale
                    const postalCodeComponent = addressComponents.find(
                      (component) => component.types.includes("postal_code")
                    );
          
                    const address = place.formatted_address;
                    const postalCode = postalCodeComponent ? postalCodeComponent.short_name : "";
                    const city = cityComponent ? cityComponent.short_name : "";
                    const province = provinceComponent ? provinceComponent.short_name : "";
                    const region = regionComponent ? regionComponent.long_name : "";
                    const streetNumber = streetNumberComponent ? streetNumberComponent.short_name : "";
  
                    newOptions.push({
                      ...result,
                      description: `${address}, ${city}, ${province}, ${region}`,
                      address:`${address}`,
                      streetNumber: `${streetNumber}`,
                      postalCode: `${postalCode}`,
                      province: `${province}`,
                      city: `${city}`,
                      region: `${region}`,
                    });
                  }
          
                  resolve();
                });
              });
            });
  
            Promise.all(requests).then(() => {
              setOptions(newOptions);
            });
          }
    
          setOptions(newOptions);
        }
      });
  
      return () => {
        active = false;
      };
    }, [regionValue, inputValue, fetch, state]);
  
    const setValueOnOtherInputs = (params) => {
        const address = params.address.split(', ')[0]; 
        const postalCode = params.postalCode
        const city = params.city
        const province = params.province
        const region = params.region
        const streetNumber = params.streetNumber;
  
        setAddressValue(streetNumber.length > 0 ? address+', '+streetNumber : address)
        setPostalCodeValue(postalCode)
        setCityValue(city)
        setProvinceValue(province)
        setRegionValue(region)
    }
  
    useEffect(() => {
      /* switch(formType) {
        case 'contact':
          if(regionValue) {
            dispatch(thunks.addContacts({['region']: regionValue}));
          }
          if(addressValue) {
            dispatch(thunks.addContacts({['address']: addressValue}));
          }
          if(postalCodeValue) {
            dispatch(thunks.addContacts({['cap']: postalCodeValue}));
          }
          if(cityValue) {
            dispatch(thunks.addContacts({['city']: cityValue}));
          }
          if(provinceValue) {
            dispatch(thunks.addContacts({['province']: provinceValue}));
          }
          break;
        case 'account': 
          if(regionValue) {
            dispatch(accountsThunk.addAccounts({['region']: regionValue}));
          }
          if(addressValue) {
            dispatch(accountsThunk.addAccounts({['address']: addressValue}));
          }
          if(postalCodeValue) {
            dispatch(accountsThunk.addAccounts({['cap']: postalCodeValue}));
          }
          if(cityValue) {
            dispatch(accountsThunk.addAccounts({['city']: cityValue}));
          }
          if(provinceValue) {
            dispatch(accountsThunk.addAccounts({['province']: provinceValue}));
          }
          break;
      }  */
    }, [regionValue, provinceValue, cityValue, postalCodeValue, addressValue])

    const handleChange = (e) => { 
      const { name, value } = e.target;
  
      /* switch(formType) {
        case 'contact':
          console.log({[name]: value})
          dispatch(thunks.addContacts({[name]: value})); 
          break;
        case 'account': 
          dispatch(accountsThunk.addAccounts({[name]: value})); 
          break;
      }  */
    };

    // End google locations autocomplete

  return (
    <>
    <Grid
      container
      spacing={3}
    >
      <Autocomplete
        id="google-map-demo-address"
        sx={{ width: 400 }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        fetchDetails={true}
        disabled={isShow === 1 ? true : false}
        GooglePlacesDetailsQuery={{ fields: "geometry" }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={oldValue || addressValue}
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValueOnOtherInputs(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Cerca una via..." variant="standard" fullWidth />
        )}
        renderOption={(props, option) => {
          if(option.structured_formatting) {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];
          
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );
        
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }
        }}
      />

       {json.map((field, index) => (

            <div key={index} style={{width: '100%', marginTop: '10px'}}>
            <InputLabel for={field.name} id="title">{field.label}</InputLabel>
              {field.name === 'cap' && (
              <TextField
                  type="text"
                  id={field.name}
                  disabled={isShow === 1 ? true : false}
                  variant="standard"
                  name={field.name}
                  fullWidth
              />
              )}
              {field.name === 'city' && (
              <TextField
                  type="text"
                  id={field.name}
                  disabled={isShow === 1 ? true : false}
                  variant="standard"
                  name={field.name}
                  fullWidth
              />
              )}
              {field.name === 'province' && (
              <TextField
                  type="text"
                  id={field.name}
                  disabled={isShow === 1 ? true : false}
                  variant="standard"
                  name={field.name}
                  fullWidth
              />
              )}
              {field.name === 'state' && (
              <TextField
                  type="text"
                  id={field.name}
                  disabled={isShow === 1 ? true : false}
                  variant="standard"
                  name={field.name}
                  fullWidth
              />
              )}
              {field.name === 'region' && (
                <Select
                name="region"
                labelId="region"
                disabled={isShow === 1 ? true : false}
                variant="standard"
                style={{ padding: 0 }}
                id="region"
                fullWidth
                value={formState && !formState.region? regionValue : formState.region || ""}
                onChange={handleChange}
              >
                <MenuItem PaperProps={{sx: {padding: 0}}} value="" selected>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'Abruzzo'}>ABRUZZO</MenuItem>
                <MenuItem value={'Balisicata'}>BASILICATA</MenuItem>
                <MenuItem value={'Calabria'}>CALABRIA</MenuItem>
                <MenuItem value={'Campania'}>CAMPANIA</MenuItem>
                <MenuItem value={'Emilia romagna'}>EMILIA ROMAGNA</MenuItem>
                <MenuItem value={'Friuli-venezia giulia'}>FRIULI-VENEZIA GIULIA</MenuItem>
                <MenuItem value={'Lazio'}>LAZIO</MenuItem>
                <MenuItem value={'Liguria'}>LIGURIA</MenuItem>
                <MenuItem value={'Lombardia'}>LOMBARDIA</MenuItem>
                <MenuItem value={'Marche'}>MARCHE</MenuItem>
                <MenuItem value={'Molise'}>MOLISE</MenuItem>
                <MenuItem value={'Piemonte'}>PIEMONTE</MenuItem>
                <MenuItem value={'Puglia'}>PUGLIA</MenuItem>
                <MenuItem value={'Sardegna'}>SARDEGNA</MenuItem>
                <MenuItem value={'Sicilia'}>SICILIA</MenuItem>
                <MenuItem value={'Toscana'}>TOSCANA</MenuItem>
                <MenuItem value={'Trentino-altro adige'}>TRENTINO-ALTO ADIGE</MenuItem>
                <MenuItem value={'Umbria'}>UMBRIA</MenuItem>
                <MenuItem value={'Valle d\'aosta'}>VALLE D’AOSTA</MenuItem>
                <MenuItem value={'Veneto'}>VENETO</MenuItem>
              </Select>
              )}
            </div>

        ))}  
      </Grid>
    </>
  );
};
export default LocalizationInputs;