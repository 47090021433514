import { React, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Icona che rappresenta l'azione "vai"



const MasterDetailTable = ({ masterDetails, objectName }) => {
  const location = useLocation()
  const [page, setPage] = useState(1);
  const recordsPerPage = 5;
  const currentFormType = location.pathname.split("/")[2];

  const totalRecords = masterDetails.relatedRecords.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const recordsToShow = masterDetails.relatedRecords.slice(
    (page - 1) * recordsPerPage,
    page * recordsPerPage
  );

  const fieldNames = masterDetails.relatedRecords[0]
    ? Object.keys(masterDetails.relatedRecords[0].relatedValues).filter(
      (fieldKey) =>
        !["Proprietario", "customRelations", "ID", "Related To", masterDetails.fieldLabel].includes(fieldKey)
    )
    : [];


  const handleRedirect = (url) => {
    // Implementa la logica di reindirizzamento qui, esempio:
    window.location.href = url;
  };


  return (
    <>
      {masterDetails.relatedRecords.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {fieldNames.map((fieldName) => (
                    <TableCell
                      key={fieldName}
                      sx={{
                        width: 50, // Imposta la larghezza massima
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis', // Mostra i tre puntini se il testo supera la larghezza
                        fontWeight: 'bold',
                        backgroundColor: '#f5f5f5',

                        borderRight: "1px dotted grey",
                        color: '#3f50b5',
                      }}
                    >
                      {fieldName}
                    </TableCell>

                  ))}
                  <TableCell align="right" sx={{ position: 'sticky', right: 0, background: 'white', zIndex: 1, backgroundColor: '#f5f5f5', }}>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recordsToShow.map((masterDetail) => (
                  <TableRow
                    key={masterDetail.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    {fieldNames.map((fieldName) => (
                      <TableCell
                        key={`${masterDetail.id}-${fieldName}`}
                        sx={{
                          width: 50, // Imposta la larghezza massima
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          borderRight: "1px dotted grey",
                          textOverflow: 'ellipsis', // Mostra i tre puntini se il testo supera la larghezza
                          padding: '6px 16px', // Riduci il padding per rendere le righe più basse
                        }}
                      >
                        {masterDetail.relatedValues[fieldName]}
                      </TableCell>
                    ))}
                    <TableCell align="right" sx={{ position: 'sticky', right: 0, zIndex: 1, padding: 0, background: "#fff", borderLeft: "1px dotted black" }}>
                      <Link to={"/adminList/" + objectName + "/show/" + masterDetail.id}>
                        <IconButton
                          sx={{

                            padding: '0px', // Riduci il padding intorno all'icona per meno spazio
                            fontSize: '0.75rem', // Riduci la dimensione del testo, se applicabile
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)', // Effetto di sfondo leggermente più scuro al passaggio del mouse
                            }
                          }}
                        >
                          <ArrowForwardIosIcon sx={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.87)' }} /> {/* Icona più piccola e più scura */}
                        </IconButton>
                      </Link>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => setPage(newPage)}
            size="small" // Rende il componente Pagination più piccolo
            sx={{
              display: 'flex',
              justifyContent: 'left',
              padding: '10px 5px',
              '& .MuiPaginationItem-root': {
                minWidth: '20px', // Riduce la larghezza minima per ogni elemento della paginazione
                height: '24px', // Altezza personalizzata per ogni elemento
                margin: '0 2px', // Riduce lo spazio tra gli elementi
                '& .MuiSvgIcon-root': { // Riduce la dimensione delle icone all'interno degli elementi della paginazione
                  fontSize: '1rem',
                },
              },
            }}
          />
        </>
      ) : (
        <Typography sx={{ px: 2, fontSize: 12, color: "gray", textAlign: "center" }}>Non sono presenti {masterDetails.objectName}.</Typography>
      )}
    </>
  );
};

export default MasterDetailTable;
