import React from "react";
import Slider from "react-slick";
import { Container } from "@mui/material";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledContainer = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-arrow .slick-prev {
    z-index: 99;
   }
  .slick-slide img {
    display: block;
    margin: auto;
    max-height: 400px;
    object-fit: cover; // Adjust as per your need
  }
`;

const StyledArrow = styled.div`
  display: block;
  background: red;
`;

export default function SimpleSlider() {
    var settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            />
        );
    }

    return (
        <StyledContainer>
            <Slider {...settings}>
                <div>
                    <img src="https://placehold.co/1920x1080" alt="Slide 1"></img>
                </div>
                <div>
                    <img src="https://placehold.co/1920x1080" alt="Slide 2"></img>
                </div>
                <div>
                    <img src="https://placehold.co/1920x1080" alt="Slide 3"></img>
                </div>
            </Slider>
        </StyledContainer>
    );
}
