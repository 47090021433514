import React from "react";
import { Box, Container, Grid, Typography, List } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';


const StyledListItem = styled(ListItemText)`
    padding: 8px 16px;
    &:hover {
        background-color: none;
        text-decoration: underline;
        cursor: pointer;
    }
    .MuiListItemText-primary {
        color: #333; // Change the text color as needed
        font-weight: bold; // Adjust the font weight as necessary
    }
`;

function Footer() {
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "#14b8a6",
                marginTop: "3rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    {/* Logo Column */}
                    <Grid item xs={12} sm={3}>
                        <svg width="100" viewBox="0 0 80 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 39L40.2 0L79.8 39L61.8 78H16.8L0 39Z" fill="white" />
                            <path d="M15.6001 40.2L24.0001 31.8L39.6001 47.4L70.2001 16.8L78.6001 25.2L39.6001 64.2L15.6001 40.2Z" fill="#D44432" />
                            <path d="M78.6 25.2L63 40.8L54 33L70.2 16.8L78.6 25.2Z" fill="#14B8A6" />
                        </svg>
                    </Grid>

                    {/* Menu Column 1 */}
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6">Menu 1</Typography>
                        <List>
                            <StyledListItem button>
                                <ListItemText primary="Item 1" />
                            </StyledListItem>
                            <StyledListItem button>
                                <ListItemText primary="Item 2" />
                            </StyledListItem>
                        </List>
                    </Grid>

                    {/* Menu Column 2 */}
                    <Grid item xs={12} sm={3} >
                        <Typography variant="h6">Menu 2</Typography>
                        <List>
                            <StyledListItem button>
                                <ListItemText primary="Item 1" />
                            </StyledListItem>
                            <StyledListItem button>
                                <ListItemText primary="Item 2" />
                            </StyledListItem>
                        </List>
                    </Grid>

                    {/* Menu Column 3 */}
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6">Menu 3</Typography>
                        <List>
                            <StyledListItem button>
                                <ListItemText primary="Item 1" />
                            </StyledListItem>
                            <StyledListItem button>
                                <ListItemText primary="Item 2" />
                            </StyledListItem>
                        </List>
                    </Grid>
                </Grid>

                {/* Copyright */}
                <Typography color="textSecondary" variant="subtitle1" align="center" style={{ marginTop: '20px' }}>
                    {`© ${new Date().getFullYear()} | BiddingWave | P.IVA: 7835487354 | Powered by KPS Factory`}
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;