import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { thunks } from '../thunks/general';

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [tokens, setTokens] = useState('');
  const [loading, setLoading] = useState(false);



  const handleSubmit = async(event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    const data = new FormData(event.currentTarget);
    const dataSubmit = {
      email: data.get('email'),
      password: data.get('password'),
    };

    fetch(process.env.REACT_APP_APP_ENDPOINT+"api/auth/login", {
      method: 'POST',
      body: JSON.stringify(dataSubmit),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        const result = await response.json();
         // Stop loading after handling response

        if(result.errors) {
          Object.values(result.errors).map((error) => {
            error.map((message) => {
              toast.error(message);
            })
          })
        }

          dispatch(thunks.setUsername(result.userData.name));
          dispatch(thunks.setUserId(result.userData.id));
          //dexter fix ;)
          dispatch(thunks.setRole(result.userData.temporaryRole))
          dispatch(thunks.setTokens(result.availableTokens))
          localStorage.setItem('token', result.token);
        setLoading(false);

        navigate('/dashboard');

      })
      .catch(error => {
        setLoading(false); // Stop loading on error

        if (error.response) {
          console.error('Errore nella risposta:', error.response.data);
          toast.error('Errore nella risposta della richiesta');
        } else if (error.request) {
          console.error('Nessuna risposta ricevuta dalla richiesta:', error.request);
          toast.error('Nessuna risposta ricevuta dalla richiesta');
        } else {
          console.error('Errore durante la richiesta:', error.message);
          toast.error('Errore durante la richiesta');
        }
      });
  };



  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: '#0a97d5c7'
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{borderLeft:"4px solid #ffc200"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
        
            <Typography component="h1" variant="h5">
              Accedi
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Indirizzo email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Ricorda "
              />
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#0a97d5c7",
                    '&:hover': {
                      // Scurire leggermente il colore per l'hover
                      backgroundColor: 'rgb(15, 174, 156)'
                    },
                  }}
                  disabled={loading}
              >
                {loading ? "Caricamento..." : "Accedi"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Password dimenticata?
                  </Link>
                </Grid>
                <Grid item>
                 
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}