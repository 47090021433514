import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  Skeleton,
  Drawer,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GavelIcon from "@mui/icons-material/Gavel";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { styled } from "styled-components";

const TutteLeAstePage = () => {
  const [cards, setCards] = useState([]);
  const [filterConfigs, setFilterConfigs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  useEffect(() => {
    fetchFilterConfigs("asta");
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(selectedFilters);
  }, [selectedFilters]); // Fix: Aggiunto selectedFilters come dipendenza dell'effetto

  const fetchFilterConfigs = async (objectName, relatedObjects = []) => {
    try {
      setLoading(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          `api/auth/getFieldsForObjectForm/${objectName}`,
        { headers: headers }
      );

      let filtersForObject = [];
      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const fields = response.data[key];
          const filteredFields = fields
            .filter((field) => field.isFiltrable)
            .map((field) => ({ ...field, objectName }));
          filtersForObject = filtersForObject.concat(filteredFields);
        }
      }

      setFilterConfigs((prev) => [...prev, ...filtersForObject]);

      relatedObjects.forEach((relatedObject) => {
        if (
          !filtersForObject.some(
            (config) => config.objectName === relatedObject
          )
        ) {
          fetchFilterConfigs(relatedObject);
        }
      });
    } catch (error) {
      console.error(
        `Errore nel recupero della configurazione dei filtri per ${objectName}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const relatedObjects = ["immobile"];
    fetchFilterConfigs(relatedObjects);
  }, []);

  const fetchData = async (selectedFilters) => {
    try {
      setLoadingCard(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: `application/json`,
        "Content-Type": `application/json`,
      };

      let filtersPayload = {};

      filterConfigs.forEach((config) => {
        const objectName = config.objectName || "default";
        const fieldName = config.fieldName;
        const filterValue = selectedFilters[fieldName];

        if (filterValue !== undefined) {
          // Assicurati che l'oggetto per questo nome oggetto esista
          if (!filtersPayload[objectName]) {
            filtersPayload[objectName] = {};
          }

          // Aggiungi una condizione per gestire i diversi tipi di filtri
          switch (config.filterType) {
            case "range":
              filtersPayload[objectName][fieldName] = {
                min: filterValue.min,
                max: filterValue.max,
                step: filterValue.step,
              };
              break;
            default:
              filtersPayload[objectName][fieldName] = filterValue;
              break;
          }
        }
      });

      console.log(filtersPayload);
      // const queryString = encodeURIComponent(JSON.stringify(filtersPayload));
      const queryString = `filterPayload=${encodeURIComponent(
        JSON.stringify(filtersPayload)
      )}`;
      const url =
        process.env.REACT_APP_APP_ENDPOINT +
        `api/auth/getRecords/asta?${queryString}`;

      const response = await axios.get(url, { headers: headers });

      setCards(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoadingCard(false);
    }
  };

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const handleUpdateClick = () => {
    fetchData(selectedFilters);
  };

  const generateFilterComponent = (filterConfig) => {
    switch (filterConfig.filterType) {
      case "range":
        const minOptions = generateRangeOptions(
          filterConfig.min || 0,
          filterConfig.max || 2000,
          50
        );
        const maxOptions = generateRangeOptions(
          filterConfig.min || 0,
          filterConfig.max || 2000,
          50
        );

        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                value={
                  selectedFilters[filterConfig.fieldName]?.min ||
                  filterConfig.min ||
                  0
                }
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, {
                    ...selectedFilters[filterConfig.fieldName],
                    min: e.target.value,
                  })
                }
                displayEmpty
                sx={{ width: "100%" }}>
                {minOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={
                  selectedFilters[filterConfig.fieldName]?.max ||
                  filterConfig.max ||
                  100
                }
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, {
                    ...selectedFilters[filterConfig.fieldName],
                    max: e.target.value,
                  })
                }
                displayEmpty
                sx={{ width: "100%" }}>
                {maxOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        );
      case "select":
        const options = filterConfig?.options?.split(",") || [];

        return (
          <Select
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
            displayEmpty
            sx={{ width: "100%" }}>
            <MenuItem key="default" value="">
              Seleziona
            </MenuItem>

            {options.map((option, index) => (
              <MenuItem key={index} value={option.trim()}>
                {option.trim()}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return (
          <TextField
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
          />
        );
    }
  };

  function generateRangeOptions(min, max, step = 1) {
    const options = [];
    for (let i = min; i <= max; i += step) {
      options.push(i);
    }
    return options;
  }

  const goToRoute = (id) => {
    navigate(`/list/asta/show/${id}`);
  };

  const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#0b97d5",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#0b97d5",
    },
  }));

  return (
    <Box>
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Aste
          </Typography>
          {isSmallScreen && (
            <Button
              color="primary"
              onClick={() => setIsDrawerOpen(true)}
              sx={{ marginRight: 2 }}>
              Filtri
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <Grid container spacing={2}>
          {isSmallScreen && (
            <Drawer
              anchor="top"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}>
              <Card>
                <CardHeader title="Filtra le aste disponibili" />
                <CardContent>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {filterConfigs.map((filterConfig, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Typography variant="subtitle1">
                            {filterConfig.label}
                          </Typography>
                          {generateFilterComponent(filterConfig)}
                        </div>
                      ))}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateClick}
                        sx={{ mt: 2 }}>
                        {loading ? (
                          <Skeleton width={150} height={36} />
                        ) : (
                          "Applica Filtri"
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 1, mt: 2 }}>
                        {loading ? (
                          <Skeleton width={100} height={36} />
                        ) : (
                          "Reset"
                        )}
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
            </Drawer>
          )}
          <Grid item xs={isSmallScreen ? 12 : 3}>
            <Card>
              <CardHeader title="Filtra le aste disponibili" />
              <CardContent>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    {filterConfigs.map((filterConfig, index) => (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        <Typography variant="subtitle1">
                          {filterConfig.label}
                        </Typography>
                        {generateFilterComponent(filterConfig)}
                      </div>
                    ))}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateClick}
                      sx={{ mt: 2 }}>
                      {loading ? (
                        <Skeleton width={150} height={36} />
                      ) : (
                        "Applica Filtri"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ ml: 1, mt: 2 }}>
                      {loading ? <Skeleton width={100} height={36} /> : "Reset"}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 9} container spacing={2}>
            {loadingCard
              ? Array.from({ length: 6 }).map((_, index) => (
                  <Grid item xs={4} key={index}>
                    <Card>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={200}
                        sx={{
                          borderRadius: "8px 8px 0 0",
                          backgroundColor: "#e0e0e0",
                        }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          <Skeleton animation="wave" height={20} width="80%" />
                        </Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              backgroundColor: "#e0e0e0",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="40%"
                            />
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : cards.map((card) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={card.id}>
                    <Card>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}>
                          {card.title}
                        </Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <AccessTimeIcon sx={{ mr: 1, color: "red" }} />
                          <Typography variant="body2" color="text.secondary">
                            Scadenza: {card.duration}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <GavelIcon sx={{ mr: 1, color: "red" }} />
                          <Typography variant="body2" color="text.secondary">
                            Offerte Totali: {card.totalBidsAmount}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormatListNumberedIcon
                            sx={{ mr: 1, color: "red" }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            Quantità Offerte: {card.qtyTotalBidsAmount}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormatListNumberedIcon
                            sx={{ mr: 1, color: "red" }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            Base d'asta {card.baseAmount}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormatListNumberedIcon
                            sx={{ mr: 1, color: "red" }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            Superficie:{" "}
                            {
                              card.customRelations.Lookup?.["Immobile all'asta"]
                                ?.relatedRecords[0]?.relatedValues?.[
                                "Superficie"
                              ]
                            }
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormatListNumberedIcon
                            sx={{ mr: 1, color: "red" }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            N° Locali:{" "}
                            {
                              card.customRelations.Lookup?.["Immobile all'asta"]
                                ?.relatedRecords[0]?.relatedValues?.[
                                "N\u00b0 Locali"
                              ]
                            }
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormatListNumberedIcon
                            sx={{ mr: 1, color: "red" }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            Tipologia Immobile:{" "}
                            {
                              card.customRelations.Lookup?.["Immobile all'asta"]
                                ?.relatedRecords[0]?.relatedValues?.[
                                "Tipologia Immobile"
                              ]
                            }
                          </Typography>
                        </Box>
                        <ColoredButton
                          sx={{
                            border: "1.5px solid #0b97d5",
                            width: "100%",
                          }}
                          size="small"
                          color="primary"
                          onClick={() => goToRoute(card.id)}>
                          Vai all'asta
                        </ColoredButton>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TutteLeAstePage;
