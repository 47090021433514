import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

const File = ({ field, onChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) { 
      const reader = new FileReader();
      reader.readAsDataURL(file); // Legge il file e lo converte in una stringa base64

      reader.onload = () => {
        const base64String = reader.result;
        console.log(base64String); // Puoi rimuovere questo log dopo il testing
        setSelectedFile(file); // Imposta il file selezionato per visualizzazioni o operazioni future
        onChange(field.name, base64String); // Invia la stringa base64 come valore
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onChange(field.fieldName, null); // Rimuove il file dallo state del componente padre
  };

  return (
      <div>
        <Button
            variant="contained"
            component="label"
        >
          Carica File
          <input
              type="file"
              hidden
              onChange={handleFileChange}
          />
        </Button>
        {selectedFile && (
            <div>
              <TextField
                  disabled
                  value={selectedFile.name}
                  margin="normal"
              />
              <Button onClick={handleRemoveFile}>Rimuovi</Button>
            </div>
        )}
      </div>
  );
};

export default File;
