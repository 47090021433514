import React from "react";
import { Grid, Link, Typography, IconButton, Paper, Box, Pagination } from "@mui/material";
import DropzoneAreaExample from "components/dropzone/DropzoneArea";
import ImageIcon from "@mui/icons-material/Image";
import ArticleIcon from '@mui/icons-material/Article';




const FileMasterDetail = ({ masterDetails, fetchFormData }) => {
  const [currentPage, setCurrentPage] = React.useState(1);

  const recordsPerPage = 8; // Numero di record per pagina
  const pageCount = Math.ceil(masterDetails.relatedRecords.length / recordsPerPage); // Numero totale di pagine



  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = masterDetails.relatedRecords.slice(indexOfFirstRecord, indexOfLastRecord);


  return (
    <React.Fragment sx={{ padding: 5, display: "block" }}>
      <DropzoneAreaExample
        onUpload={fetchFormData}
        relationId={masterDetails.relationId}
      />

      {currentRecords.length > 0 ? (
        <Grid
          container
          spacing={1}
          sx={{ paddingLeft: 3 }}
        >
          {currentRecords.map((masterDetail) => {
            // Inizializza una variabile per memorizzare il titolo del file.
            let fileTitle = "Titolo non disponibile";

            // Cerca il titolo del file ("Nome File") in masterDetail.relatedValues.
            Object.entries(masterDetail.relatedValues).forEach(([fieldKey, fieldValue]) => {
              if (fieldKey === "Nome File") {
                fileTitle = fieldValue; // Memorizza il titolo del file.
              }
            });

            return (
              <Grid item xs={12} md={3} key={masterDetail.id}>
                <Paper
                  sx={{
                    boxShadow: 0,
                    padding: 0,
                    marginBottom: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "top"
                  }}
                >
                  {Object.entries(masterDetail.relatedValues).map(
                    ([fieldKey, fieldValue]) => {
                      // Filtra i campi non necessari o indesiderati.
                      if (
                        fieldKey === "Proprietario" ||
                        fieldKey === "customRelations" ||
                        fieldKey === "ID" ||
                        fieldKey === masterDetails.fieldLabel ||
                        !fieldValue
                      ) {
                        return null;
                      }

                      if (fieldKey.toLowerCase() === "file") {
                        return (
                          <React.Fragment key={`${masterDetail.id}-${fieldKey}`}>
                            <Link
                              href={fieldValue} // URL del file
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                justifyContent="center"
                                sx={{
                                  '&:hover': {
                                    '.icon': { color: 'blue', backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                    '.file-name': { textDecoration: 'underline' },
                                  }
                                }}
                              >
                                <ArticleIcon
                                  className="icon"
                                  sx={{
                                    color: "black",
                                    fontSize: '3.75rem',
                                    borderRadius: '50%',
                                    padding: '8px',
                                    transition: 'all 0.3s ease',
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  component="p"
                                  className="file-name"
                                  sx={{
                                    fontSize: '0.75rem',
                                    mt: 1,
                                    transition: 'all 0.3s ease',
                                  }}
                                >
                                  {fileTitle} {/* Mostra il titolo del file */}
                                </Typography>
                              </Box>
                            </Link>
                          </React.Fragment>
                        );
                      }


                    }
                  )}
                </Paper>
              </Grid>
            );
          })}



          <Grid item xs={12}>
            <Pagination
              count={pageCount}
              size="small"
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ mt: 2, display: 'flex', justifyContent: 'left' }} // Stilizzazione della paginazione
            />
          </Grid>
        </Grid>
      ) : (
        <Typography>

          <Typography sx={{ px: 2, fontSize: 12, color: "gray", textAlign: "center" }}>Non sono presenti {masterDetails.objectName}.</Typography>
        </Typography>
      )}
    </React.Fragment>
  );
};

export default FileMasterDetail;
