import React, { useState, useEffect, useMemo, startTransition } from 'react';
import { Card, CardContent, Typography, Button, Grid, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Modal, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { lazy, Suspense } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { thunks } from '../../thunks/general';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const StripePaymentForm = lazy(() => import('../Stripe/StripePaymentForm'));



const showToast = (message, type) => {
    switch (type) {
        case 'success':
            toast.success(message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        case 'error':
            toast.error(message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        default:
            toast.info(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
    }
};


const Wallet = () => {
    const [saldoToken, setSaldoToken] = useState(0);
    const [quantitaToken, setQuantitaToken] = useState('');
    const [metodoPagamento, setMetodoPagamento] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [transazioni, setTransazioni] = useState([]);
    const [totale, setTotale] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const appearance = {
        theme: 'stripe'
    };

    const fetchSaldo = async () => {
        try {
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            };

            const response = await axios.get(process.env.REACT_APP_APP_ENDPOINT+'api/auth/wallet/saldo', { headers: headers });
            setSaldoToken(response.data.saldoWallet);
            dispatch(thunks.setTokens(response.data.saldoWallet))
        } catch (error) {
            console.error('Error fetching saldo: ', error);
        }
    };


    const fetchTransazioni = async () => {
        try {
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            };

            const response = await axios.get(process.env.REACT_APP_APP_ENDPOINT+'api/auth/getRecords/transazioni?owner=mine', { headers });
            setTransazioni(response.data.transazioni);
        } catch (error) {
            console.error('Errore nel recupero delle transazioni: ', error);
        }
    };
    useEffect(() => {
        // Qui inserisci la logica per ottenere il saldo del wallet
        // Per esempio, potresti fare una richiesta API e aggiornare il saldoToken


        fetchTransazioni();
        fetchSaldo();
    }, []);

    const acquistaToken = async () => {
        // Qui inserisci la logica per acquistare token
        // Questo potrebbe includere una richiesta POST a un API
        try {
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            };

            const response = await axios.post(process.env.REACT_APP_APP_ENDPOINT+'api/auth/acquistaToken', {
                quantita: quantitaToken
            }, { headers: headers });

            // Aggiorna il saldo dopo l'acquisto
            setSaldoToken(response.data.nuovoSaldo);

            setQuantitaToken('');
        } catch (error) {
            console.error('Error buying tokens: ', error);
        }
    };

    useEffect(() => {
        // Aggiorna il totale ogni volta che cambia quantitaToken
        setTotale(quantitaToken * 100); // 1 token = 100 euro
    }, [quantitaToken]);

    const handlePaymentMethodChange = (event) => {
        setMetodoPagamento(event.target.value);
    };

    const showModal = (message) => {
        setModalMessage(message);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Stile per il modale (puoi personalizzarlo)
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const createOrder = (data, actions) => {
        // Assicurati che totale sia maggiore di zero
        if (parseFloat(totale) <= 0) {
            console.error("Il totale deve essere maggiore di zero per procedere con il pagamento.");
            return Promise.reject(new Error("Il totale deve essere maggiore di zero."));
        }

        return actions.order.create({
            purchase_units: [{
                amount: {
                    currency_code: "EUR", // Sostituisci con la tua valuta
                    value: totale.toString(),
                },
            }],
        }).then((orderId) => {
            console.log("Order ID:", orderId); // Log dell'ID dell'ordine
            fetchTransazioni();
            fetchSaldo();
            return orderId; // Restituisce l'ID dell'ordine creato
        }).catch((error) => {
            console.error("Errore nella creazione dell'ordine:", error);
            // Gestisci qui l'errore
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(async (details) => {
            const name = details.payer.name.given_name;
            try {
                const headers = {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                };

                // Invio dei dati dell'ordine al backend
                await axios.post(process.env.REACT_APP_APP_ENDPOINT+'api/auth/saveOrder', {
                    orderDetails: details,
                    tokenAmount: quantitaToken,
                }, { headers });

                // Mostra toast di successo
                showToast(`Transazione completata da: ${name}`, 'success');

                // Aggiorna il saldo del wallet
                await fetchSaldo();

            } catch (error) {
                console.error('Errore durante l\'invio dell\'ordine al backend:', error);
                // Mostra toast di errore
                showToast('Errore durante la transazione', 'error');
            }

        });
    };

    const initialOptions = {
        clientId: "ARTlXODwyOTLT3ETzqARSFcHtzTQRRsUF5BasQJH7MjR27gJa5U5U0tFwRvCv_FRzwlROsUyUMOg3225",
        currency: "EUR",
        intent: "capture",
        amount: quantitaToken,
        disableFunding: "card,mybank"
    };


    const stripePromise = useMemo(() => loadStripe("pk_test_XUIpXpyaGuuw0Dc9Ng80xFWs"), []);


    return (
        <PayPalScriptProvider options={initialOptions}>
            <Elements stripe={stripePromise} options={{ appearance }}>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Saldo del Wallet
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {saldoToken} Token
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Acquista Token
                        </Typography>
                        <TextField
                            label="Quantità Token"
                            variant="outlined"
                            fullWidth
                            value={quantitaToken}
                            onChange={(e) => setQuantitaToken(e.target.value)}
                            type="number"
                            margin="normal"
                        />
                        <Typography sx={{ mt: 2 }}>
                            Totale: €{totale}
                        </Typography>
                        <FormControl component="fieldset" sx={{ mt: 2 }}>
                            <FormLabel component="legend">Metodo di Pagamento</FormLabel>
                            <RadioGroup
                                row
                                aria-label="metodo di pagamento"
                                name="row-radio-buttons-group"
                                value={metodoPagamento}
                                onChange={handlePaymentMethodChange}
                            >
                                <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                <FormControlLabel value="stripe" control={<Radio />} label="Carta di Credito" />
                            </RadioGroup>
                        </FormControl>
                        {/* Placeholder per PayPal */}
                        {metodoPagamento === 'paypal' && (
                            <PayPalButtons
                                createOrder={createOrder}
                                onApprove={onApprove}
                            />
                        )}
                        {/* Placeholder per Stripe */}
                        {metodoPagamento === 'stripe' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <StripePaymentForm totale={totale} totalToken={quantitaToken} appearance={appearance} updateWallet={fetchSaldo}/>
                            </Suspense>
                        )}

                    </CardContent>
                </Card>
            </Grid>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Messaggio
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                </Box>
            </Modal>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Lista Transazioni
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="tabella transazioni">
                                <TableHead>
                                    <TableRow>

                                        <TableCell align="left">Data</TableCell>
                                        <TableCell>ID Transazione</TableCell>
                                        <TableCell align="right">Metodo di Pagamento</TableCell>
                                        <TableCell align="right">Quantità Token</TableCell>
                                        <TableCell align="right">EURO</TableCell>
                                        {/* Aggiungi altre colonne se necessario */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(transazioni) && transazioni.map((transazione) => (
                                        <TableRow
                                            key={transazione.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell align="right">{transazione.data}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {transazione.id}
                                            </TableCell>
                                            <TableCell align="right">{transazione.method}</TableCell>
                                            <TableCell align="right">{transazione.quantita}</TableCell>
                                            <TableCell align="right">{transazione.amount}</TableCell>
                                            {/* Aggiungi altre celle se necessario */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            </Elements>
        </PayPalScriptProvider>

    );
};

export default Wallet;