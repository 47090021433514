import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Scheduler from "react-mui-scheduler";
import axios from 'axios';

import {
  Card,
  CardContent,
  Grid,
  Link,
  InputLabel,
  Typography,
  FormControl,
  Select,
  CircularProgress,
  CardActions,
  Drawer,
  Tab,
  Button,
  Avatar,
  ButtonGroup,
  Stack,
  Box,
  CardHeader,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
} from "@mui/material";
import { Edit as EditIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import DynamicFormComponentModal from "../components/modalDynamicform";

function Calendar({ customOptions, customToolbarProps, relatedRecordId }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Per memorizzare la data selezionata
  const [eventType, setEventType] = useState('appuntamenti');
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [state] = useState({
    options: {
      transitionMode: "zoom", // or fade
      startWeekOn: "mon", // or sun
      defaultMode: "month", // or week | day | timeline
      minWidth: 540,
      maxWidth: 540,
      minHeight: 370,
      maxHeight: 370,
      ...customOptions,
    },
    /*     alertProps: {
      open: true,
      color: "info", // info | success | warning | error
      severity: "info", // info | success | warning | error
      message: "🚀 Let's start with awesome react-mui-scheduler 🔥 🔥 🔥",
      showActionButton: true,
      showNotification: true,
      delay: 1500,
    }, */
    toolbarProps: {
      showSearchBar: true,
      showSwitchModeButtons: true,
      showDatePicker: true,
      ...customToolbarProps,
    },
  });



  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      try {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        const baseUrl = 'https://kpsfactory.datadomus.com/public/api/auth/getRecords/appuntamenti';
        const url = relatedRecordId ? `${baseUrl}/${relatedRecordId}` : baseUrl + '?owner=me';

        const response = await axios.get(url, { headers }); const fetchedEvents = response.data;

        const convertedEvents = fetchedEvents.map(event => ({
          id: `event-${event.id}`,
          label: event.title,
          groupLabel: event.title,
          user: event.owner_id,
          color: event.status === 'Confermato' ? '#099ce5' : '#f28f6a', // Example color condition
          startHour: formatAMPM(new Date(event.dateTimeInizio)), // Format to "hh:mm AM/PM"
          endHour: formatAMPM(new Date(event.dateTimeFine)),
          date: new Date(event.dateTimeInizio).toISOString().slice(0, 10), // Format to "YYYY-MM-DD"

          createdAt: new Date(),
          createdBy: "Kristina Mayer",
        }));

        setEvents(convertedEvents);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
      setIsLoading(false);
    };

    fetchEvents();
  }, []);


  // Funzione per aprire il drawer e impostare la data selezionata
  const handleCellClick = (event, row, day) => {

    setIsDrawerOpen(true);
    const dateObject = new Date(day.date);
    // Formatta la data in un formato più leggibile, adattato alla località 'it-IT'
    const formattedDate = dateObject.toLocaleDateString('it-IT', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',

    });

    // Formatta l'ora
    const formattedTime = dateObject.toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit'
    });

    setSelectedDate(formattedDate);
  };
  // Funzione per chiudere il drawer
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };


  const handleEventTypeChange = (event) => {
    setIsLoading(true); // Mostra il loader
    const newEventType = event.target.value;
    // Simula un ritardo di caricamento del form (es. richiesta API)
    setTimeout(() => {
      setEventType(newEventType);
      setIsLoading(false); // Nasconde il loader dopo il cambio di form
    }, 500); // 500ms per il ritardo di simulazione
  };


  const handleEventClick = (event, item) => {
    // Do something...
  };

  const handleEventsChange = (item) => {
    // Do something...
  };

  const handleAlertCloseButtonClicked = (item) => {
    // Do something...
  };

  return (
    <>
      <CardHeader
        sx={{
          padding: 0,
          backgroundColor: "#e2e2e2",
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          marginBottom: 1
        }}
        avatar={
          <Avatar >
            <AccountCircleIcon />
          </Avatar>
        }
        title={"Calendario"}
        titleTypographyProps={{ variant: "h6" }}

      />
      <Scheduler
        onCellClick={handleCellClick}
        locale="it"
        events={events}
        legacyStyle={false}
        options={state?.options}
        alertProps={state?.alertProps}
        toolbarProps={state?.toolbarProps}
        onEventsChange={handleEventsChange}
        onTaskClick={handleEventClick}
        onAlertCloseButtonClicked={handleAlertCloseButtonClicked}
      />

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        sx={{ zIndex: 1290 }}
      >
        <div style={{ width: 350, padding: 20 }}>
          <h2>Crea un nuovo</h2>
          <FormControl fullWidth>
            <InputLabel id="event-type-select-label">Tipo di Record</InputLabel>
            <Select
              labelId="event-type-select-label"
              id="event-type-select"
              value={eventType}
              label="Tipo di record"
              onChange={handleEventTypeChange}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="eventi">Evento</MenuItem>
              <MenuItem value="appuntamenti">Appuntamento</MenuItem>
              <MenuItem value="chiamata">Chiamata</MenuItem>
              <MenuItem value="scadenze">Scadenza</MenuItem>
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DynamicFormComponentModal
              objectType={eventType.toLowerCase()} // Assicurati che corrisponda con i tuoi objectType
              prefilledValues={{
                owner_id: "general.userId", // Assicura che general.userId sia definito correttamente
              }}
            />
          )}
        </div>
      </Drawer>
    </>

  );
}


function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}


export default Calendar;
