import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { thunks } from '../../thunks/general';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, CardContent, Grid, InputLabel, IconButton, Stack, CardMedia, CardHeader, TableContainer, TableHead, Paper, Table, TableBody,ListItemAvatar, Avatar,ListItemText, ListItem, Divider,List, TextField, TableRow, TableCell, Container} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Integer from '../../components/customInputs/Integer';
import Sliderimage from '../../components/SliderImage';
import HomeIcon from '@mui/icons-material/Home'; // Import the icon you want to use
import TimerIcon from '@mui/icons-material/Timer';
import GavelIcon from '@mui/icons-material/Gavel';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DrawerAppBar from "../../components/layout/AppBar";
import Footer from "../../components/layout/Footer";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const defaultTheme = createTheme();




export default function ShowAsta({values}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState(values || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);

  const [timeLeft, setTimeLeft] = useState('');
  const [comments, setComments] = useState([
    {
      author: "John Doe",
      date: "2022-01-01 10:00",
      content: "This is an amazing auction!",
      authorPhoto: "/path/to/photo1.jpg" // Replace with actual image path
    },
    {
      author: "Jane Smith",
      date: "2022-01-02 12:30",
      content: "Can't wait to see the final bid.",
      authorPhoto: "/path/to/photo2.jpg" // Replace with actual image path
    }
  ]);
  const [newComment, setNewComment] = useState('');
  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const submitComment = () => {
    if (newComment.trim() !== '') {
      setComments([...comments, newComment]);
      setNewComment('');
    }
  };

  const [targetDateTime, setTargetDateTime] = useState('2024-01-11T22:00:00')
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const distance = new Date(targetDateTime) - now;

      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft('Asta terminata');
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft(`${days} giorni ${hours} ore ${minutes} minuti ${seconds} secondi`);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDateTime]);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInviaOfferta = () => {
    console.log(`Sending offer: ${bidAmount}`);
    handleCloseModal();
  };


  return (
    <ThemeProvider theme={defaultTheme}>

      <Box sx={{ display: 'flex' }}>


        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >



          <>
            <Container maxWidth="lg">
              <DrawerAppBar />
            <Grid container spacing={2} sx={{ marginTop: 0 }}>

              <Grid item xs={12} md={8}>

                <Card sx={{ borderRadius: 0 }}>
                  <Sliderimage />
                  <CardContent>
                    <div>
                        <Typography variant="h5" color="textSecondary" component="p" sx={{marginBottom: 0}}>
                          Dettagli Inserzione
                        </Typography>
                     <Typography variant="h6" color="textSecondary" component="p" sx={{marginBottom: 2}}>
                       L'asta terminerà il <b>{targetDateTime}</b>
                        </Typography>

                          <Table>
                            <TableBody>
                              {/* Row 1 */}
                              <TableRow>
                                <TableCell>
                                  <IconButton color="inherit" aria-label="home">
                                    <HomeIcon />
                                  </IconButton>
                                  Superficie
                                </TableCell>
                                <TableCell>
                                  45 MQ
                                </TableCell>
                                <TableCell>
                                  <IconButton color="inherit" aria-label="home">
                                    <HomeIcon />
                                  </IconButton>
                                  Superficie
                                </TableCell>
                                <TableCell>
                                  45 MQ
                                </TableCell>
                              </TableRow>
                              {/* Row 2 */}
                              <TableRow>
                                <TableCell>
                                  <IconButton color="inherit" aria-label="home">
                                    <HomeIcon />
                                  </IconButton>
                                  Superficie
                                </TableCell>
                                <TableCell>
                                  45 MQ
                                </TableCell>
                                <TableCell>
                                  <IconButton color="inherit" aria-label="home">
                                    <HomeIcon />
                                  </IconButton>
                                  Superficie
                                </TableCell>
                                <TableCell>
                                  45 MQ
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>

                    </div>

                    {/* Description */}
                    <Typography variant="h5" color="textSecondary" sx={{marginTop: 3, marginBottom: 2}}>
                      Descrizione
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                      Qui puoi inserire una descrizione completa dell'inserzione. Questo testo può
                      essere lungo quanto vuoi e includere tutti i dettagli rilevanti che desideri
                      condividere con i tuoi utenti.
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardContent>
                    <Typography variant="h5">Commenti ({comments.length})</Typography>
                    <List>
                      {comments.map((comment, index) => (
                          <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar alt={comment.author} src={comment.authorPhoto} />
                              </ListItemAvatar>
                              <ListItemText
                                  primary={comment.content}
                                  secondary={`${comment.author}, ${comment.date}`}
                              />
                            </ListItem>
                            {index < comments.length - 1 && <Divider variant="inset" component="li" />}
                          </React.Fragment>
                      ))}
                    </List>
                    <div>
                      <TextField
                          label="Scrivi un commento"
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          value={newComment}
                          onChange={handleCommentChange}
                          sx={{ mt: 2 }}
                      />
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={submitComment}
                          sx={{ mt: 2 }}
                      >
                        Invia
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ borderRadius: 0 }}>
                  <CardContent>
                    <h2 sx={{margin: 0}}>Dettagli Asta</h2>
                    <Grid container spacing={0} sx={{ marginTop: 0 }}>
                      {/* Time Left */}
                      <Grid item xs={12}>
                        <IconButton edge="start" color="inherit">
                          <TimerIcon />
                        </IconButton>
                        Tempo residuo alla fine dell'asta: <div><b>{timeLeft}</b></div>
                      </Grid>
                      {/* High Bid */}
                      <Grid item xs={12}>
                        <IconButton edge="start" color="inherit">
                          <GavelIcon />
                        </IconButton>
                        Rilancio più alto: $21,000
                      </Grid>
                      {/* Total Bids */}
                      <Grid item xs={12}>
                        <IconButton edge="start" color="inherit">
                          <FormatListNumberedIcon />
                        </IconButton>
                        Totale quantità Rilanci: 14
                      </Grid>
                    </Grid>
                    {errorMessage && (
                        <Typography color="error" sx={{ marginTop: 2 }}>
                          {errorMessage}
                        </Typography>
                    )}
                    <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
                      Segui Asta
                    </Button>
                  </CardContent>



                  {/* Rilancio Asta Button */}



                </Card>

                <Card sx={{ borderRadius: 0, marginTop:2 }}>
                  <CardContent>
                    <h2 sx={{marginLeft: 20}}>Fai un'offerta</h2>
                    <Grid  spacing={3}>
                      Time Left
                      6:16:58
                      High
                      Bid
                      $21,000
                      Bids
                      14
                      Comments
                      25
                    </Grid>
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                  </CardContent>



                  {/* Rilancio Asta Button */}
                  <CardContent>
                    <Button variant="contained" onClick={handleOpenModal}>
                      Fai un Rilancio
                    </Button>
                  </CardContent>


                </Card>
              </Grid>
            </Grid>

            {/* Modal for Rilancio Asta */}
            <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
              <DialogContent sx={{paddingLeft: 0}}>
                <DialogTitle  sx={{ position: 'relative', paddingTop: 0, paddingBottom: 0}}>
                  Effettua un rilancio
                  <IconButton
                      edge="end"
                      color="inherit"
                      onClick={handleCloseModal}
                      sx={{ position: 'absolute', right: 20, top: 0, padding: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <Container>
                  <Box display="flex" flexDirection="column" alignItems="left">
                    <div sx={{ width: '50px' }}>
                      <Integer value={bidAmount} onValueChange={(value) => setBidAmount(value)}   />
                    </div>
                    <Stack direction="row" spacing={2} mt={2}>
                      <Button variant="contained" onClick={handleInviaOfferta}>
                        Invia Offerta
                      </Button>
                      <Button variant="contained" onClick={handleCloseModal}>
                        Annulla
                      </Button>
                    </Stack>
                  </Box>
                </Container>
              </DialogContent>
            </Dialog>
            </Container>
            <Footer />
          </>
          
        </Box>
      </Box>
    </ThemeProvider>
  );
}