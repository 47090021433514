import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { format, parseISO } from 'date-fns';
import Pagination from '@mui/material/Pagination';
import { CardHeader } from "@mui/material";
import { Edit as EditIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { it } from 'date-fns/locale';
import {
  AccessTime as AccessTimeIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";

const formatDate = (dateString) => {
  try {
    // Assuming dateString is in ISO format, otherwise adjust parsing accordingly
    const date = parseISO(dateString);
    return format(date, 'PPp', { locale: it }); // Example: '02/04/2024'
  } catch (error) {
    console.error('Error formatting date:', error);
    return "Data non disponibile";
  }
};


const EmailContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const EmailBody = styled(Box)`
  margin-top: 8px;
  padding: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.875rem;
`;

const DetailItem = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const EmailDetails = styled(Box)`
  margin-left: 0px; // Alinea i dettagli con il testo dell'email, considerando l'avatar
`;

const AttachmentsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

const EmailItem = ({
  email,
  sender,
  senderEmail,
  cc,
  ccn,
  avatar,
  subject,
  date,
  time,
  body,
  attachments,

}) => (
  <>

    <EmailContainer>
      <HeaderContainer>
        <Typography variant="subtitle2">
          Da: {sender}{" "}
          <Typography
            variant="body2"
            component="span"
          >{`<${email?.relatedValues['Proprietario'], email?.relatedValues['Proprietario']}>`}</Typography>
        </Typography>
        <DetailItem variant="caption">
          <AccessTimeIcon
            sx={{ fontSize: "0.875rem", mr: 0.5 }}
            color="action"
          />
          {formatDate(email?.relatedValues['Creato il'], email?.relatedValues['Ora'])}

        </DetailItem>
      </HeaderContainer>

      <EmailDetails>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Oggetto: {email?.relatedValues.Oggetto}
        </Typography>
        <EmailBody
          dangerouslySetInnerHTML={{ __html: email?.relatedValues.Email }}
        />
        {attachments && attachments.length > 0 && (
          <AttachmentsContainer>
            <AttachFileIcon sx={{ mr: 0.5 }} color="action" />
            <Typography variant="caption">
              {attachments.length} allegati
            </Typography>
          </AttachmentsContainer>
        )}
      </EmailDetails>
    </EmailContainer>
  </>
);
const EmailSection = ({ emails }) => {
  const [page, setPage] = useState(1);

  if (emails.relatedRecords.length === 0) {
    console.error("Invalid comments prop format or empty comments array.");
    return null;
  }

  const itemsPerPage = 3; // Numero di commenti per pagina

  const count = Math.ceil(emails.relatedRecords.length / itemsPerPage); // Numero totale di pagine

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calcola quali commenti mostrare nella pagina corrente
  const emailList = emails.relatedRecords.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div>
      <CardHeader
        sx={{
          padding: 0,
          backgroundColor: "#e2e2e2",
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          marginBottom: 1,
          color: "white",
          backgroundColor: "#10a6e9",
        }}
        avatar={
          <Avatar >
            <AccountCircleIcon />
          </Avatar>
        }
        title={"Conversazioni email"}
        titleTypographyProps={{ variant: "h6" }}

      />
      <List>
        {
          emailList.map((email) => (
            <EmailItem key={email.id} email={email} />
          ))}
      </List>
      <Pagination count={count} page={page} onChange={handleChangePage} />
      <hr></hr>
    </div>
  );
};

export default EmailSection;
