import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DynamicWizard from "../../components/dynamic-wizard";
import { Dialog, DialogContent } from "@mui/material";
import toast from "react-hot-toast";
import SimpleDynamicForm from "../../components/simpleDynamicform";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BuildIcon from "@mui/icons-material/Build";
import e from "cors";

export default function LeMieInserzioni() {
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [localLoader, setLocalLoader] = useState(true);
  const [isModalNewInserzione, setIsModalNewInserzione] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModalCreate = () => setIsModalCreateOpen(true);
  const closeModalCreate = () => setIsModalCreateOpen(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      setLocalLoader(true);
      // Modifica l'URL per ottenere solo le tue inserzioni
      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          "api/auth/getRecords/immobile?owner=me",
        { headers }
      );
      console.log(response.data);
      const fetchedCards = response.data.map((item, index) => ({
        id: item.id,
        title: item.houseType + " in " + item.title || `Immobile ${index + 1}`,
        description: item.duration,
        status: item.status || "#",
        imageUrl:
          item.customRelations.Lookup?.["Immobile all'asta"]?.relatedRecords[0]
            ?.relatedValues?.["Immagine di Copertina"] ||
          "https://via.placeholder.com/150",
      }));
      setCards(fetchedCards);

      toast.success("Dati salvati con successo!");
    } catch (error) {
      toast.error("Errore durante il recupero dei dati: " + error.message);

      console.error("Error fetching data: ", error);
    } finally {
      setLocalLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goToRoute = (id) => {
    setSelectedRecordId(id);
    setIsModalOpen(true);
  };

  const createNewInserzione = () => {
    navigate("/dashboard/immobile/create");
  };
  const handleCreateNewInserzione = () => {
    setIsModalNewInserzione(true);
  };
  const handleCardClick = (e, id) => {
    e.stopPropagation();
    setSelectedRecordId(id);
    setIsModalOpen(true);
  };

  function StatusCell(props) {
    const { status } = props;

    let color = "default";
    let icon = null;
    let label = "";

    switch (status) {
      case "Approvato":
        color = "green";
        icon = <CheckCircleIcon />;
        label = "Approvato";
        break;
      case "Rifiutato":
        color = "red";
        icon = <CancelIcon />;
        label = "Rifiutato";
        break;
      case "Richiesta Modifica":
        color = "orange";
        icon = <BuildIcon />;
        label = "Modifica";
        break;
      case "Nuovo":
        color = "darkblue";
        icon = <BuildIcon />;
        label = "Nuovo";
        break;
      default:
        label = status || "--";
    }

    return (
      <Button
        startIcon={icon}
        disabled
        variant="contained"
        style={{
          backgroundColor: color,
          color: "white",
          cursor: "default",
          padding: "3px 10px",
          textTransform: "none",
        }}>
        {label}
      </Button>
    );
  }
  const handleCreateAsta = (e, id) => {
    e.stopPropagation();
    setSelectedRecordId(id);
    setIsModalCreateOpen(true);
  };
  const ColoredButton = styled(Button)(({ theme }) => ({
    size: "small",
    backgroundColor: "#0b97d5",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#0b97d5",
    },
  }));
  const ModifyButton = styled(Button)(({ theme }) => ({
    size: "small",
    "&:hover": {
      backgroundColor: "transparent",
      color: `${theme.palette.secondary.main} !important`,
    },
  }));
  return (
    <>
      <Grid container spacing={2} sx={{ padding: "20px" }}>
        <Grid item xs={12}>
          <Card sx={{ padding: "10px", textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleCreateNewInserzione()}>
              Crea Nuova Inserzione
            </Button>
          </Card>
        </Grid>

        {!localLoader && cards.length === 0 && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Non hai ancora inviato le tue inserzioni, comincia adesso!
              </Typography>
            </Box>
          </Grid>
        )}

        {!localLoader && cards.length > 0
          ? cards.map((card) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={card.id}>
                <Card
                  onClick={(e) => handleCardClick(e, card.id)}
                  sx={{ cursor: "pointer" }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={card.imageUrl}
                    alt="Immagine Inserzione"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}>
                      {card.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {card.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1, mb: 2 }}>
                      <StatusCell status={card.status} />
                    </Typography>
                    <ColoredButton
                      sx={{ marginRight: "1rem" }}
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={(e) => handleCardClick(e, card.id)}>
                      Vedi Inserzione
                    </ColoredButton>

                    {card.status === "Approvato" && (
                      <ColoredButton
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={(e) => handleCreateAsta(e, card.id)}>
                        Crea Asta
                      </ColoredButton>
                    )}

                    {card.status === "Richiesta Modifica" && (
                      <ModifyButton
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => goToRoute(card.id)}>
                        Modifica
                      </ModifyButton>
                    )}

                    {card.status === "Asta in Corso" && (
                      <ColoredButton
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => goToRoute(card.id)}>
                        Vai all'asta
                      </ColoredButton>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))
          : // Mostra gli scheletri durante il caricamento
            Array.from({ length: 8 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <Skeleton variant="rectangular" height={140} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      <Skeleton animation="wave" height={20} width="80%" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Skeleton animation="wave" height={15} width="60%" />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}>
                      <Skeleton animation="wave" height={15} width="40%" />
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}>
                      <Skeleton animation="wave" height={25} width="40%" />
                      <Skeleton animation="wave" height={25} width="40%" />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
      </Grid>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          {selectedRecordId && (
            <SimpleDynamicForm
              objectType="immobile"
              recordId={selectedRecordId}
              title="Visualizza"
              showButtons={false}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isModalCreateOpen}
        onClose={() => setIsModalCreateOpen(false)}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          {selectedRecordId && (
            <DynamicWizard
              objectType="asta"
              recordId={selectedRecordId}
              title="Crea Asta"
              onClose={() => setIsModalCreateOpen(false)}
              reloadRecords={fetchData}
              prefilledValues={{ title: selectedRecordId }}
              hiddenFields={{ title: true }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isModalNewInserzione}
        onClose={() => setIsModalNewInserzione(false)}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          {selectedRecordId && (
            <DynamicWizard
              objectType="immobile"
              recordId={selectedRecordId}
              title="Crea Nuova Inserzione"
              onClose={() => setIsModalNewInserzione(false)}
              reloadRecords={fetchData}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
