import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ProtectedRoute({ redirectTo = "/" }) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(redirectTo);
    }

    // Se siamo nella pagina "/" e abbiamo il token, reindirizza a "dashboard"
    if (token && window.location.pathname === "/") {
      navigate('/dashboard');
    }
  }, [token, navigate, redirectTo]);

  return <Outlet />;
}
