import React, { useState, useEffect } from "react";
import axios from "axios";
import FieldsRenderer from "./fields-renderer";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  Button,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

function ModalSimpleDynamicForm({
  heading,
  prefilledValues,
  objectType,
  recordId,
  title,
  showButtons = true,
  isEdit,
  hiddenFields,
  onCloseModal,
  updateData,
  onClose,
}) {
  const [formData, setFormData] = useState(prefilledValues || {});
  const [fieldsData, setFieldsData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // Presumiamo che i dati siano in caricamento all'inizio

  const currentFormType = objectType || location.pathname.split("/")[2];
  const currentRecordId = recordId || location.pathname.split("/")[4];

  console.log("hiddenFields", hiddenFields);
  const handleFetchError = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const processFormFields = (sections) => {
    if (typeof sections !== "object" || sections === null) {
      handleFetchError("Errore durante il caricamento, aggiorna la pagina.");
      return {};
    }

    return Object.entries(sections).reduce(
      (processedSections, [sectionName, fields]) => {
        if (!Array.isArray(fields) || fields.length === 0) {
          processedSections[sectionName] = [];
          return processedSections;
        }

        processedSections[sectionName] = fields.map((field) => ({
          name: field.fieldName,
          label: field.label,
          type: field.type,
          relatedObject: field.relatedObject,
          required: field.required === "1",
          isReadOnly: field.isReadOnly === "1" || field.isReadOnly === true,
          options: field.options || [],
          validationRules: field.validationRules || "",
        }));
        return processedSections;
      },
      {}
    );
  };

  const fetchFormData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      let recordResponse = { data: prefilledValues || {} };
      if (currentRecordId) {
        //   recordResponse = await axios.get(`${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`, { headers });
      }

      const fieldsResponse = await axios.get(
        `${endpoint}api/auth/getFieldsForObjectForm/${currentFormType}`,
        { headers }
      );

      //   setFormData(recordResponse.data);
      setFieldsData(processFormFields(fieldsResponse.data));
    } catch (error) {
      toast.error("Errore durante il recupero dei dati.");
    }
  };

  useEffect(() => {
    if (currentFormType) {
      fetchFormData().then(() => {
        setIsLoading(false); // Imposta isLoading su false una volta che i dati sono caricati
      });
    }
  }, [currentFormType, currentRecordId]);

  // ... Resto del componente (handleSave, handleSetEdit, handleInputChange, renderFields)

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const endpoint =
        process.env.REACT_APP_APP_ENDPOINT +
        `api/auth/storeRecord/${objectType}`;
      const response = await axios.post(endpoint, formData, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });

      if (response.status === 200) {
        // Estrai il recordId e objectName dalla risposta
        const recordId = response.data.id;
        const objectName = response.data.custom_object.objectName;
        const objectLabel = response.data.custom_object.label;
        toast.success(objectLabel + " creato con successo!");
        updateData && updateData();
        onCloseModal && onCloseModal();
      } else {
        toast.error("Errore durante il salvataggio dei dati.");
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data || "Errore durante il salvataggio dei dati.";
        toast.error(errorMessage);
      } else {
        toast.error("Errore di connessione o problema del server.");
      }
    }
  };

  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const renderFields = () => {
    // Se i dati stanno caricando, mostra un loader al centro
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    // Altrimenti, renderizza i campi normalmente
    return (
      <>
        <Grid container spacing={0}>
          {Object.entries(fieldsData).map(
            ([sectionName, sectionFields], sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      borderRadius: "5px",
                      padding: "5px 10px",
                      backgroundColor: "#2ba0dd",
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      boxShadow: "1px 1px 2px #979797",


                    }}
                  >
                    {sectionName}
                  </Typography>
                </Grid>
                {
                  sectionFields.map((field, fieldIndex) => {
                    const isReadOnly = Boolean(field.isReadOnly);

                    if (
                      isReadOnly ||
                      (hiddenFields &&
                        Object.keys(hiddenFields).includes(field.name))
                    ) {
                      return null;
                    }

                    // Renderizza il campo solo se non è di sola lettura
                    return (
                      <Grid key={fieldIndex} item xs={12} md={12}>
                        <InputLabel htmlFor={field.name}>
                          {field.label}
                          {field.required && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </InputLabel>

                        <FieldsRenderer
                          field={field}
                          value={formData[field.fieldName]}
                          onChange={handleInputChange}
                          prefilledValues={prefilledValues}
                        />
                      </Grid>
                    );
                  })
                }
              </React.Fragment>
            )
          )}
        </Grid >
      </>
    );
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "95vh" }}
    >
      <CardContent style={{ overflow: "auto", flexGrow: 1 }}>
        {renderFields()}
        {modalMessage}
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
          backgroundColor: "white",
          position: "sticky",
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Button variant="outlined" onClick={onClose} sx={{ marginRight: 1 }}>
          Annulla
        </Button>
        <Button variant="outlined" onClick={handleSave}>
          Salva
        </Button>
      </Box>
    </div>
  );
}

export default ModalSimpleDynamicForm;
