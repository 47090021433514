import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import TokenIcon from '@mui/icons-material/CurrencyBitcoin'; // 
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import StarRateIcon from '@mui/icons-material/StarRate';
import EuroIcon from '@mui/icons-material/Euro';


function Dashboard() {
    const general = useSelector((state) => state.general);

    // Dati fittizi per la demo
    const data = {
        totalAuctions: 120,
        activeUsers: 350,
        tokensSold: 5000,
    };
    const dataInserzioni = [
        { name: 'Settimana 1', Approvate: 30, InAttesa: 20, Rifiutate: 5 },
        { name: 'Settimana 2', Approvate: 45, InAttesa: 25, Rifiutate: 10 },
        { name: 'Settimana 3', Approvate: 20, InAttesa: 18, Rifiutate: 8 },
        { name: 'Settimana 4', Approvate: 27, InAttesa: 15, Rifiutate: 12 },
    ];




    // Dati di esempio
    const dataAgenzieMasters = [
        { name: 'Agenzie', value: 400 },
        { name: 'Masters', value: 300 },
    ];

    const COLORS = ['#0088FE', '#FF8042'];


    const dataAgenzieToken = [
        { name: 'Agenzia 1', Token: 200 },
        { name: 'Agenzia 2', Token: 300 },
        { name: 'Agenzia 3', Token: 150 },
        // ... altri dati
    ];

    // Per mostrare il grafico



    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card sx={{ m: 1 }}>
                    <CardContent>
                        <Typography variant="h4" >
                            Benvenuto!
                        </Typography>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="p" sx={{}}>Benvenuti nella sezione introduttiva della vostra nuova dashboard di Customer Relationship Management (CRM), attualmente in una dinamica fase di sviluppo. Il nostro obiettivo principale è quello di fornirvi un'interfaccia intuitiva e ricca di funzionalità, progettata per ottimizzare le vostre strategie di interazione con i clienti e massimizzare le performance del vostro business.
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="p" sx={{}}>Nella fase corrente, stiamo lavorando assiduamente per integrare strumenti avanzati di analisi dei dati che vi consentiranno di comprendere meglio le esigenze e le preferenze dei vostri clienti. Questi strumenti sono pensati per aiutarvi a personalizzare le vostre offerte e campagne di marketing in modo più efficace, garantendo un'esperienza utente superiore che si traduce in tassi di conversione più alti e in una maggiore fedeltà del cliente. </Typography>
                        </Box>



                    </CardContent>
                </Card>
            </Grid>



        </Grid>
    );
}

export default Dashboard;