import React, { useState, useEffect } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const StyledDropzoneContainer = styled.div`
  margin-top: 20px;
  .MuiDropzoneArea-root {
    background-color: #f0f8ff; // Colore di sfondo di base leggermente azzurro
    transition: background-color 0.3s ease; // Transizione per l'effetto hover
    min-height: 100px;
    margin-bottom: 20px;
    &:hover {
      background-color: #e0f7fa; // Cambia il colore di sfondo al passaggio del mouse
      border-color: #82b1ff; // Cambia il colore del bordo al passaggio del mouse
    }
  }
`;

const dropzoneStyles = {
  icon: {
    color: "green",
    width: "51px!important",
    height: "51px!important",
  },
};

const DropzoneAreaExample = ({ relationId, onUpload }) => {
  const [files, setFiles] = useState([]);
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const general = useSelector((state) => state.general);
  const location = useLocation();


  useEffect(() => {
    const recordId = location.pathname.split("/")[4];
    setCurrentRecordId(recordId);
  }, [location]);

  const handleChange = (newFiles) => {
    setFiles(newFiles);
  };

  useEffect(() => {
    if (files.length > 0) {
      convertBlobToBase64();
    }
  }, [files]);

  const convertBlobToBase64 = () => {
    if (files.length === 0) {
      return;
    }

    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        // Passa sia la stringa base64 che il nome del file alla funzione callMockApiEndpoint per ogni file
        callMockApiEndpoint(base64String, file.name);
      };
      reader.readAsDataURL(file);
    });

    setFiles([])
  };

  const callMockApiEndpoint = (base64String, fileName) => {
    const endpoint = process.env.REACT_APP_APP_ENDPOINT;
    const mockApiEndpoint = `${endpoint}api/auth/storeRecord/file`;
    const token = localStorage.getItem("token");
    axios
      .post(
        mockApiEndpoint,
        {
          file: base64String,
          title: fileName, // Includi il nome del file nel payload
          related: relationId + ":" + currentRecordId,
          owner_id: general.userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Mock API Response:", response.data);
        onUpload && onUpload();
        toast.success("File caricato con successo!");
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        toast.error("Errore durante il caricamento del file.");
      });
  };

  return (
    <StyledDropzoneContainer>
      <DropzoneArea
        onChange={handleChange}
        acceptedFiles={["image/*", "application/pdf"]}
        maxFileSize={5000000}
        filesLimit={5}
        dropzoneText="Clicca o trascina i file qui"
        showPreviewsInDropzone={false}
        showPreviews={false}
        showAlerts={true}
        showFileNamesInPreview={true}
        classes={dropzoneStyles}
      />
    </StyledDropzoneContainer>
  );
};

export default DropzoneAreaExample;
