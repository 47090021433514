import React, { useState, useEffect } from "react";
import axios from "axios";
import FieldsRenderer from "./fields-renderer";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  Button,
  Box,
  Modal,
  Typography,
  Avatar
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Edit as EditIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import AutocompleteJSONFake from './customInputs/AutocompleteJSONFake';

function SimpleDynamicForm({
  prefilledValues,
  objectType,
  recordId,
  title,
  showButtons = true,
  hiddenFields,
  editMode
}) {
  const [formData, setFormData] = useState(prefilledValues || {});
  const [fieldsData, setFieldsData] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const currentFormType = objectType
    ? objectType
    : location.pathname.split("/")[2];
  const currentRecordId = recordId ? recordId : location.pathname.split("/")[4];
  const [isEditMode, setIsEditMode] = useState(editMode || false);

  const fetchFormData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      const getRecord = axios.get(
        `${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`,
        { headers }
      );
      const getFields = axios.get(
        `${endpoint}api/auth/getFieldsForObjectForm/${currentFormType}`,
        { headers }
      );

      const [recordResponse, fieldsResponse] = await Promise.all([
        getRecord,
        getFields,
      ]);

      setFormData(recordResponse.data);
      setFieldsData(fieldsResponse.data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il recupero dei dati.";
      toast.error(errorMessage);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (currentFormType && currentRecordId) {
      fetchFormData();
    }
  }, [currentFormType, currentRecordId]);

  const handleSave = async () => {
    try {
      const modeApi = !isEditMode
        ? "storeRecord/" + currentFormType
        : "updateRecord/" + currentFormType + "/" + currentRecordId;

      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/" + modeApi,
        formData,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );

      fetchFormData();
      toast.success("Dati salvati con successo!");
      handleSetEdit();
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il salvataggio dei dati.";

      toast.error(errorMessage);
    }
  };

  const handleSetEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const renderFields = () => {
    return (
      <Grid container spacing={2}>
        {Object.entries(fieldsData).map(([sectionKey, fields]) =>
          fields.map((field) => {
            const isReadOnly = Boolean(field.isReadOnly);
            if (field.type === "Photo" || isReadOnly || field.type === "File") {
              return null;
            }

            if (hiddenFields &&
              Object.keys(hiddenFields).includes(field.fieldName)) {
              return null
            }


            return (
              <Grid item xs={12} md={12} key={field.id}>
                {isEditMode ? (
                  <>
                    <InputLabel htmlFor={field.name} >
                      <b>{field.label}</b>
                      {field.required && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </InputLabel>
                    <FieldsRenderer
                      field={field}
                      value={formData[field.fieldName]}
                      onChange={handleInputChange}
                      prefilledValues={prefilledValues}
                    />
                  </>
                ) : (
                  <>
                    {field.type === "Lookup" || field.type === "MasterDetail" ? (
                      <>
                        <InputLabel htmlFor={field.name} onClick={handleSetEdit}>
                          <b>{field.label}</b>
                        </InputLabel>
                        <AutocompleteJSONFake
                          onClick={handleSetEdit}
                          value={formData[field.fieldName]}
                          field={field}
                          fieldName={field.fieldName}
                        />
                      </>
                    ) : (
                      <>
                        <InputLabel htmlFor={field.name} onClick={handleSetEdit}>
                          <b>{field.label}</b>
                        </InputLabel>
                        {field.type === "File" ? null : (
                          <Typography
                            onClick={handleSetEdit}>
                            <i>{formData[field.fieldName]}</i>
                          </Typography>
                        )}
                      </>
                    )}


                    <hr style={{ margin: 0 }} />
                  </>
                )}
              </Grid>
            );
          })
        )}
      </Grid>
    );
  };

  return (
    <div sx={{ borderRadius: 0 }}>
      <CardHeader
        sx={{
          padding: 0,
          color: "white",
          backgroundColor: "#10a6e9",
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        avatar={
          <Avatar sx={{ bgcolor: isEditMode ? "secondary.main" : "primary.main" }}>
            {isEditMode ? <EditIcon /> : <AccountCircleIcon />}
          </Avatar>
        }
        title={title || "Dettagli"}
        titleTypographyProps={{ variant: "h6" }}

      // Aggiungi qui altre prop che ti servono
      />
      <Card> <CardContent>
        {renderFields()}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            ...(isEditMode && {
              // Applica questi stili solo in modalità di modifica
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: "white",
              padding: "8px",
              boxShadow: "0 -2px 4px rgba(0,0,0,0.1)",
              zIndex: 9000,
            }),
          }}
        >
          {showButtons &&
            (!isEditMode ? (
              <Button variant="outlined" onClick={handleSetEdit}>
                Modifica
              </Button>
            ) : (
              <>
                <Button variant="contained" onClick={handleSetEdit} sx={{ marginRight: "8px" }}>
                  Annulla
                </Button>
                <Button variant="contained" onClick={handleSave}>
                  Salva
                </Button>
              </>
            ))}
        </Box>

      </CardContent></Card>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={
            {
              /* stili per la modale */
            }
          }>
          <Typography variant="h6" component="h2">
            {modalMessage}
          </Typography>
          <Button onClick={() => setIsModalOpen(false)}>OK</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default SimpleDynamicForm;
