import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Pagination from '@mui/material/Pagination';

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { AccessTime as AccessTimeIcon } from "@mui/icons-material";
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import { CardHeader } from "@mui/material";
import { Edit as EditIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';


const formatDate = (dateString) => {
  try {
    // Assuming dateString is in ISO format, otherwise adjust parsing accordingly
    const date = parseISO(dateString);
    return format(date, 'PPp', { locale: it }); // Example: '02/04/2024'
  } catch (error) {
    console.error('Error formatting date:', error);
    return "Data non disponibile";
  }
};
const CommentContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
`;

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledListItemText = styled(ListItemText)`
  flex-grow: 1;
  margin-left: 16px;
`;

const CommentList = styled(List)`
  margin-bottom: 0px;
`;

const EmailBody = styled(Box)`
  margin-top: 0px;
  padding: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.875rem;
`;


const Comment = ({ name, avatar, relatedValues, date }) => (
  <CommentContainer>

    <Box sx={{ ml: 0, width: "100%" }}>
      <HeaderContainer>
        <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {/* Box per titolo e proprietario, allineati a sinistra */}
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            {/* Titolo Nota con maggiore evidenza */}
            <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold' }}>
              {relatedValues && relatedValues["Titolo Nota"]
                ? relatedValues["Titolo Nota"]
                : null}
            </Typography>

            {/* Proprietario come sottotitolo, più piccolo e meno in evidenza */}
            <Typography variant="caption" component="span" sx={{ color: 'text.secondary' }}>
              Scritto da {relatedValues && relatedValues["Proprietario"]
                ? relatedValues["Proprietario"]
                : null}
            </Typography>
          </Box>

          {/* Box per la data, allineata a destra */}
          <Typography
            variant="caption"
            sx={{ display: "flex", alignItems: "top" }}
          >
            <AccessTimeIcon sx={{ fontSize: "0.875rem", mr: 0.5 }} color="action" />
            {relatedValues && relatedValues["Creato il"]
              ? formatDate(relatedValues["Creato il"])
              : null}
          </Typography>
        </Box>
      </HeaderContainer>
      <EmailBody>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {relatedValues && relatedValues.Nota
            ? relatedValues.Nota
            : null}
        </Typography>
      </EmailBody>
    </Box>
  </CommentContainer>
);
const CommentSection = ({ comments }) => {
  const [page, setPage] = useState(1);

  if (comments.relatedRecords.length === 0) {
    console.error("Invalid comments prop format or empty comments array.");
    return null;
  }

  const itemsPerPage = 3; // Numero di commenti per pagina

  const count = Math.ceil(comments.relatedRecords.length / itemsPerPage); // Numero totale di pagine

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calcola quali commenti mostrare nella pagina corrente
  const commentList = comments.relatedRecords.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div>
      <CommentList>
        <CardHeader
          sx={{
            padding: 0,
            backgroundColor: "#e2e2e2",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            marginBottom: 1,
            color: "white",
            backgroundColor: "#10a6e9",
          }}
          avatar={
            <Avatar >
              <AccountCircleIcon />
            </Avatar>
          }
          title={"Note Recenti"}
          titleTypographyProps={{ variant: "h6" }}

        />

        {commentList.map((comment) => (
          <Comment
            key={comment.id}
            name={comment.relatedValues.Proprietario}
            avatar="https://placehold.co/100"
            relatedValues={comment.relatedValues}
            date={comment.date}
          />
        ))}
      </CommentList>
      <Pagination count={count} page={page} onChange={handleChangePage} />
      <hr></hr>
    </div>
  );
};


export default CommentSection;
