import React from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// Rimuovi l'importazione di DemoContainer se non stai facendo una demo
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Configura i plugin di dayjs
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const Datetime = ({ field, value, onChange }) => {
  const handleDateChange = (newValue) => {
    onChange(field.fieldName, newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* Rimuovi DemoContainer se non è necessario per il tuo caso d'uso */}
      <DateTimePicker
          fullwidth
          value={value}
          onChange={handleDateChange}
          renderInput={(params) => <TextField fullwidth {...params} />}
      />
    </LocalizationProvider>
  );
};

export default Datetime;
