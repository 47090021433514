import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import { Box, Container, CssBaseline, Fab } from "@mui/material";

import Kanban from "components/layout/Kanban";
import List from "pages/Adminlist/List";

const PageKanBan = () => {
    const [viewMode, setViewMode] = useState("list");
    const params = useParams()
    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === "list" ? "kanban" : "list"));
    };



    useEffect(() => {
        setViewMode("list")
      }, [params]);
    return (
        <div
            component="main"
            maxWidth="xl"
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
            <CssBaseline />
       
            {viewMode === "list" ? <List setViewMode={setViewMode} /> : <Kanban setViewMode={setViewMode} />}
        </div>
    );
};

export default PageKanBan;
