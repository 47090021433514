import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Unstable_Grid2 as Grid,
  TextField,
  IconButton
} from '@mui/material';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Date = ({ fieldName, isCreate, isEdit, isShow, values, fieldErrors }) => {
    const [formValues, setFormValues] = useState({});
    const dispatch = useDispatch();
    const fieldRef = useRef(null);
    const pageForm = localStorage.getItem('formType')

    const handleInputChange = (e, name) => {
        const { value } = e.target;
        const fieldNamesByType = {};
        fieldNamesByType[fieldName] = value;

        fieldRef.current.querySelector('input').style.border = 'none';

        setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
        }));
        
      };

    // Verifica se ci sono errori per questo campo
    const firstPartFieldName = fieldName.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g)[0];
    const secondPartFieldName = fieldName.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g)[1];

    const spacedFieldName = firstPartFieldName + (secondPartFieldName ? ' ' + secondPartFieldName.toLowerCase() : '');  

    const regex = new RegExp(spacedFieldName, "i"); // "i" rende la ricerca case-insensitive
    const isFieldNameInErrors = regex.test(fieldErrors);

    useEffect(() => {
      if (isFieldNameInErrors) {
        // Seleziona l'input all'interno del riferimento e applica lo stile
        fieldRef.current.querySelector('input').style.border = '4px solid red';
    
        // Imposta il focus sul campo con errore
        fieldRef.current.querySelector('input').focus();
      }
    }, [isFieldNameInErrors]);

  return (
    <>
    {isCreate === 1 ? (
    <TextField
    type="date"
    id={fieldName}
    name={fieldName}
    onChange={(e) => handleInputChange(e, fieldName)}
    fullWidth
    className={isFieldNameInErrors ? 'error' : ''}
    helperText={fieldErrors || ''}
    ref={fieldRef} 
    />
    ) : (
      <TextField
        type="date"
        id={fieldName}
        variant="standard"
        size="large"
        fullWidth
        name={fieldName}
        defaultValue={isShow === 1 && values !== undefined ? values[fieldName] : ''}
        disabled={isShow === 0 ? false : true}
        onChange={(e) => handleInputChange(e, fieldName)}

      />
    )}
  </>
  );
};

export default Date;