// GoogleMapsContext.js
import React, { createContext, useContext, useState, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const GoogleMapsContext = createContext(null);

export const useGoogleMaps = () => useContext(GoogleMapsContext);

export const GoogleMapsProvider = ({ children }) => {
  const googleLibraries = useMemo(() => ['places', 'geometry'], []);
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA",
    libraries: googleLibraries,
    language: 'it',
  });

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};
