import React, { useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const Title = styled(Typography)`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Description = styled(Typography)`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 200px; /* Larghezza complessiva del countdown */
  margin-bottom: 2rem;
`;

const CountdownUnit = styled.div`
  background-color: #f0f0f0; /* Grigio chiaro */
  padding: 10px 20px;
  border-radius: 5px;
  margin: 30px;
`;

const ComingSoonPage = () => {
  const deadlineDate = new Date("2024-05-01T00:00:00Z"); // Data di scadenza (esempio: 1 Maggio 2024)

  const calculateTimeRemaining = () => {
    const currentTime = new Date();
    const timeDifference = deadlineDate - currentTime;
    return Math.max(timeDifference, 0);
  };

  const [countdown, setCountdown] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(calculateTimeRemaining());
    }, 1000);

    // Pulisce l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []);

  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  return (
    <Root>
      <Title variant="h1">Prossimamente</Title>
      <Description variant="body1">
        Stiamo lavorando duramente per portarti qualcosa di fantastico. Resta
        sintonizzato!
      </Description>
      <CountdownContainer>
        <CountdownUnit>
          <Typography variant="h4">{days}</Typography>
          <Typography variant="body1">giorni</Typography>
        </CountdownUnit>
        <CountdownUnit>
          <Typography variant="h4">{hours}</Typography>
          <Typography variant="body1">ore</Typography>
        </CountdownUnit>
        <CountdownUnit>
          <Typography variant="h4">{minutes}</Typography>
          <Typography variant="body1">minuti</Typography>
        </CountdownUnit>
        <CountdownUnit>
          <Typography variant="h4">{seconds}</Typography>
          <Typography variant="body1">secondi</Typography>
        </CountdownUnit>
      </CountdownContainer>
      <Button variant="contained" color="primary" component={RouterLink} to="/">
        Torna alla Home
      </Button>
    </Root>
  );
};

export default ComingSoonPage;
