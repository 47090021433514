import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// ... altri import necessari ...

const AutocompleteJSON = ({ field, fieldName, formType, isCreate, isEdit, isShow, values, fieldErrors, onChange, value }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const fieldRef = useRef(null);

    console.log(values)


    useEffect(() => {
        const fetchOptions = async () => {
            try {
                console.log('field', field);
                const endpoint = process.env.REACT_APP_APP_ENDPOINT + `api/auth/getRecords/${field.relatedObject}`;
                const response = await axios.get(endpoint, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                console.log('response', response);

                if (Array.isArray(response.data)) {
                    let extractedOptions = [];

                    if (field.relatedObject === 'User') {
                        extractedOptions = response.data.map(user => ({
                            id: user.id,
                            label: `${user.name} (${user.email}) - ${user.temporaryRole}`,
                        }));
                    } else if (field.relatedObject == "All") {
                        extractedOptions = response.data.map(item => ({
                            id: "0:${item.id}",
                            label: item.title
                        }));

                    } else {
                        extractedOptions = response.data.map(item => ({
                            id: item.id,
                            label: item.title
                        }));
                    }

                    setOptions(extractedOptions);
                    const processValue = (fieldValue) => {

                        // Assicurati che fieldValue sia una stringa prima di chiamare metodi specifici delle stringhe
                        const valueAsString = String(fieldValue);

                        // Controlla se il valore (ora sicuramente una stringa) è nel formato "idRelazione:idRecord"
                        if (valueAsString.includes(':')) {
                            // Estrae l'ID del record dal valore
                            const [, recordId] = valueAsString.split(':');
                            return recordId;
                        }

                        return fieldValue;
                    };


                    console.log('valori', value, values);
                    if (value) {
                        const processedValue = processValue(value); // Processa il valore per estrarre l'ID del record se necessario
                        const selected = extractedOptions.find(option => option.id == processedValue);
                        console.log('Selected option:', selected);
                        setSelectedOption(selected || null);

                        onChange(fieldName, processedValue); // Usa il valore processato
                    }

                    if (values && values[field.name]) {

                        const processedValue = processValue(values[field.name]); // Processa il valore per estrarre l'ID del record se necessario

                        const selected = extractedOptions.find(option => option.id === processedValue);
                        console.log('Selected option:', selected);

                        setSelectedOption(selected || null);
                        onChange(field.name, processedValue); // Usa il valore processato
                    }

                }
            } catch (error) {
                console.error('Errore nella chiamata all\'endpoint:', error);
            }
        };

        fetchOptions();
    }, [field, value]);

    const handleSelectionChange = (event, newValue) => {
        setSelectedOption(newValue);
        console.log('newValue', newValue)
        onChange(field.name || fieldName, newValue ? newValue.id : null);
    };

    useEffect(() => {
        if (fieldErrors && fieldRef.current) {
            fieldRef.current.style.border = '2px solid red';
        }
    }, [fieldErrors]);

    const getOptionLabel = (option) => option.label;

    return (
        <Autocomplete

            sx={{ height: "50px" }}
            ref={fieldRef}
            multiple={false}
            name={field.name}
            options={options}
            placeholder="Clicca per scrivere"
            getOptionLabel={getOptionLabel}
            value={selectedOption}
            onChange={handleSelectionChange}
            renderInput={(params) => (
                <TextField {...params} label="Clicca per cercare" variant="standard" error={Boolean(fieldErrors)} helperText={fieldErrors || ''} />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />)
            }
        />
    );
};

export default AutocompleteJSON;
