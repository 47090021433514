import { slice } from '../slices/general';

const setRole = (params) => async (dispatch) => {
    dispatch(slice.actions.setRole(params));
};
const setTokens = (params) => async (dispatch) => {
    dispatch(slice.actions.setTokens(params));
};
const setUserId = (params) => async (dispatch) => {
    dispatch(slice.actions.setUserId(params));
};
const setUsername = (params) => async (dispatch) => {
    dispatch(slice.actions.setUsername(params));
}; 
const resetState = () => async (dispatch) => {
    dispatch(slice.actions.resetState());
};  

export const thunks = {
    setRole,
    setUsername,
    setUserId,
    setTokens,
    resetState
};