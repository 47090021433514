import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton } from "@mui/material";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Textarea = ({
  field,
  fieldName,
  isCreate,
  isEdit,
  isShow,
  values,
  fieldErrors,
  onChange,
}) => {
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const fieldRef = useRef(null);
  const pageForm = localStorage.getItem("formType");

  const handleInputChange = (e) => {
    onChange(field.fieldName, e.target.value);
  };

  return (
    <TextField
      multiline
      id={fieldName}
      name={fieldName}
      onChange={handleInputChange}
      rows={3}
      maxRows={10}
      fullWidth
      defaultValue={values && values[fieldName]}
      disabled={isShow}
      ref={fieldRef}
    />
  );
};

export default Textarea;
