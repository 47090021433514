import React from "react";
import { Grid, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        component="container"
        sx={{
          paddingTop: "100.5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ fontSize: "80px" }}>404 Not Found</h1>
        <p style={{ fontSize: "20px" }}>
          La pagina che stai cercando non è presente sul nostro sito o non è più
          disponibile.
        </p>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          <Link to="/dashboard">
            <Button
              variant="contained"
              sx={{
                background: "red",
                fontFamily: "Chakra Petch, sans-serif",
                fontSize: "16px",
                borderRadius: "3px",
                background: "#007AFF",
                color: "#fff",
                p: "12px 24px",
                lineHeight: 1,
                textDecoration: "none",
                "&:hover": {
                  background: "#2196F3",
                  textDecoration: "none",
                },
              }}
            >
              Vai alla Home
            </Button>
          </Link>
        </div>
      </Grid>
    </>
  );
};

export default NotFoundPage;
