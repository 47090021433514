import React, { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { HTML5Backend } from "react-dnd-html5-backend";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
    Paper,
    Typography,
    Button,
    Fab,
    Card,
    Grid,
    CardContent,
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import KanbanIcon from "@mui/icons-material/ViewAgenda";
import ListIcon from "@mui/icons-material/List";

import axios from "axios";
import { styled } from "@mui/material/styles";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.grey,
        color: theme.palette.common.black,
        width: "400px"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const CustomStyledTableCell = ({ children, moveCard, opportunity, columnIndex }) => {
    const [, drop] = useDrop({
        accept: "CARD",
        drop: (draggedItem) => {
            moveCard(
                draggedItem.opportunity,
                opportunity,
                draggedItem.columnIndex,
                columnIndex
            );
        },
        canDrop: () => true,
    });

    const [{ isOver }, ref] = useDrop({
        accept: "CARD",
        drop: (draggedItem) => {
            moveCard(
                draggedItem.opportunity,
                opportunity,
                draggedItem.columnIndex,
                columnIndex
            );
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <StyledTableCell
            sx={{
                verticalAlign: "top",
                minWidth: "300px",
                borderRight: "2px solid lightgrey",
                transition: "background-color 0.4s ease", // Aggiungi la transizione CSS

                backgroundColor: isOver ? "lightgrey" : "transparent", // Cambia colore se il drag è sopra
                opacity: isOver ? 0.5 : 1, // Cambia colore se il drag è sopra
            }}
            ref={(node) => {
                ref(drop(node));
            }}
        >
            {children}
        </StyledTableCell>
    );
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
}));

const API_ENDPOINT = "http://localhost:8000/api/opportunities"; // Cambia l'endpoint dell'API

const initialData = [
    {
        title: "To Do",
        opportunities: [
            { id: 1, name: "Opportunity 1", value: 100 },
            { id: 2, name: "Opportunity 2", value: 200 },
            { id: 3, name: "Opportunity 3", value: 150 },
        ],
    },
    {
        title: "In Progress",
        opportunities: [
            { id: 4, name: "Opportunity 4", value: 300 },
            { id: 5, name: "Opportunity 5", value: 250 },
        ],
    },
    {
        title: "Review",
        opportunities: [
            { id: 6, name: "Opportunity 6", value: 120 },
            { id: 7, name: "Opportunity 7", value: 180 },
        ],
    },
    {
        title: "Completed",
        opportunities: [
            { id: 8, name: "Opportunity 8", value: 400 },
        ],
    },
    {
        title: "Archived",
        opportunities: [
            { id: 9, name: "Opportunity 9", value: 75 },
            { id: 10, name: "Opportunity 10", value: 50 },
        ],
    },
];

const rowStyle = {
    height: "80vh",
};

const KanbanCard = ({ opportunity, columnIndex, moveCard }) => {
    const [, ref] = useDrag({
        type: "CARD",
        item: { opportunity, columnIndex },
    });

    return (
        <div
            ref={(node) => ref(node)}
            style={{
                marginBottom: "8px",
            }}
        >
            <Paper
                elevation={3}
                style={{
                    padding: "8px",
                    cursor: "grab",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "5px",
                    transition: "background-color 0.3s ease", // Aggiungi la transizione CSS
                    "&:hover": {
                        backgroundColor: "#000", // Cambia il colore di sfondo al passaggio del mouse
                    },
                }}
            >
                <Typography variant="h6">{opportunity.name}</Typography>
                <Typography variant="body">
                    <b>Descrizione: </b>Lorem ipsum dolor
                </Typography>
                <Typography variant="body2">{`Totale: ${opportunity.value}`}€</Typography>
            </Paper>
        </div>
    );
};

const columnCellStyle = {
    minWidth: "200px", // Imposta la larghezza minima desiderata
    height: "100%", // Altezza delle celle delle colonne
    display: "flex", // Utilizza un layout flessibile per allineare le carte in alto
    flexDirection: "column", // Imposta la direzione del layout flessibile in colonna
};
const Kanban = ({ setViewMode }) => {
    const [columns, setColumns] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const currentFormType = location.pathname.split("/")[2];
    useEffect(() => {
        // Simula un ritardo di caricamento
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const moveCard = (draggedOpportunity, targetOpportunity, fromColumnIndex, toColumnIndex) => {
        try {

            // Aggiorna lo stato locale della Kanban board
            const updatedColumns = [...columns];
            const fromColumn = updatedColumns[fromColumnIndex];
            const toColumn = updatedColumns[toColumnIndex];

            const fromOpportunityIndex = fromColumn.opportunities.findIndex((op) => op.id === draggedOpportunity.id);

            if (fromOpportunityIndex !== -1) {
                fromColumn.opportunities.splice(fromOpportunityIndex, 1);
                toColumn.opportunities.push(draggedOpportunity);

                setColumns(updatedColumns);
            }
        } catch (error) {
            console.error("Error moving opportunity:", error);
        }
    };

    const calculateTotalValue = (column) => {
        return column.opportunities.reduce((total, opportunity) => {
            return total + opportunity.value;
        }, 0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card sx={{ margin: 3, padding: "20px 10px 0 10px" }}>
                    <CardContent>
                        <>
                            <Typography variant="h5" sx={{ paddingLeft: 0 }}>
                                <AccountBoxIcon
                                    fontSize="large"
                                    style={{ verticalAlign: "middle" }}
                                />{" "}
                                {currentFormType.charAt(0).toUpperCase() + currentFormType.slice(1)}

                                <Fab
                                    sx={{ marginLeft: 3 }}
                                    color="default"
                                    onClick={() => setViewMode("list")}
                                    aria-label="List View"
                                    size="small"
                                >
                                    <ListIcon />
                                </Fab>
                                <Fab
                                    color={"primary"}
                                    onClick={() => setViewMode("kanban")}
                                    aria-label="Kanban View"
                                    size="small"
                                >
                                    <KanbanIcon />
                                </Fab>
                            </Typography>



                        </>
                        <DndProvider backend={HTML5Backend}>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#FFF",
                                }}
                            >
                                <Table sx={{ minWidth: 700, backgroundColor: "#FFF" }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, columnIndex) => (
                                                <StyledTableCell key={columnIndex}>
                                                    <Typography variant="h6">{column.title}</Typography>
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow style={rowStyle} className="columnRow">
                                            {columns.map((column, columnIndex) => (
                                                <CustomStyledTableCell key={columnIndex} moveCard={moveCard} opportunity={null} columnIndex={columnIndex}>
                                                    {Array.isArray(column.opportunities) &&
                                                        column.opportunities.map((opportunity, index) => (
                                                            <KanbanCard
                                                                key={opportunity.id}
                                                                opportunity={opportunity}
                                                                columnIndex={columnIndex}
                                                                moveCard={moveCard}
                                                            />
                                                        ))}
                                                </CustomStyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    border: "1px solid #ddd",
                                    marginTop: "16px",
                                    backgroundColor: "#FFF",
                                    position: "sticky",
                                    bottom: "0",
                                }}
                            >
                                <Table
                                    sx={{ minWidth: 700, backgroundColor: "#FFF" }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <StyledTableRow>
                                            {columns.map((column, columnIndex) => (
                                                <CustomStyledTableCell
                                                    key={columnIndex}
                                                    style={{
                                                        height: "calc(100% - 300px)", // Altezza rimanente per i totali

                                                    }}
                                                >
                                                    <Typography variant="body2">
                                                        Valore Totale: {calculateTotalValue(column)}€
                                                    </Typography>
                                                </CustomStyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </DndProvider>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Kanban;
