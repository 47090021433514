import * as React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";

import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Stack,
  Switch,
} from "@mui/material";
const CustomInputLabel = (props) => (
  <label htmlFor={props.htmlFor} id={props.id} {...props.sx}>
    {props.children}
  </label>
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function IlMioProfilo() {
  const [value, setValue] = React.useState(0);
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
  };
  return (
    <Grid container padding={2}>
      <Grid item lg={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: "row",
              }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}>
                <Tab label="Informazioni Personali" {...a11yProps(0)} />
                <Tab label="Altre Info" {...a11yProps(1)} />
                <Tab label="Elimina Account" {...a11yProps(2)} />
                {/* Aggiungi altre schede se necessario */}
              </Tabs>
              <TabPanel value={value} index={0}>
                <div style={{ width: "100vh" }}>
                  <Typography variant="h6">Informazioni Personali</Typography>
                  <label htmlFor="avatar-input">
                    <Avatar
                      alt="User Avatar"
                      src="/"
                      sx={{
                        cursor: "pointer",
                        width: 100,
                        height: 100,
                        textAlign: "center",
                        objectFit: "cover",
                        color: "transparent",
                      }}
                    />
                    <input
                      id="avatar-input"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleAvatarChange}
                    />
                  </label>
                  <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        id="fullName"
                        label={
                          <CustomInputLabel
                            htmlFor="fullName"
                            id="fullName-label"
                            sx={{
                              position: "relative",
                              top: fullName ? 0 : "50%",
                            }}>
                            Full Name
                          </CustomInputLabel>
                        }
                        variant="filled"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={fullName}
                        placeholder="Franco Gioia"
                        onChange={handleFullNameChange}
                      />
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center">

                      </Stack>
                    </div>

                    <div style={{ display: "flex" }}>
                      <TextField
                        id="email"
                        label={
                          <CustomInputLabel
                            htmlFor="email"
                            id="email-label"
                            sx={{
                              position: "relative",
                              top: email ? 0 : "50%",
                            }}>
                            Email
                          </CustomInputLabel>
                        }
                        variant="filled"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={email}
                        placeholder="franco@gioia.com"
                        onChange={handleEmailChange}
                      />
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center">

                      </Stack>
                    </div>
                  </Stack>
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Typography variant="h6">Lorem Ipsum</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: 1,
                  }}>
                  <Typography variant="body2">
                    Make Contact Info Public
                    <br />
                    Means that anyone viewing your profile will be able to see
                    your contact details.
                  </Typography>
                  <span>
                    <Switch />
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 2,
                  }}>
                  <Typography variant="body2">
                    Available to hire
                    <br />
                    Toggling this will let your teammates know that you are
                    available for acquiring new projects.
                  </Typography>
                  <span>
                    <Switch />
                  </span>
                </Box>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <Typography variant="h6">Elimina Account</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginTop: 1 }}>
                  Delete your account and all of your source data. This is
                  irreversible.
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginTop: 2, textTransform: "none" }}>
                  Delete Account
                </Button>
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default IlMioProfilo;
