import * as React from 'react';
import { Tabs, Tab, Typography, Box, Card, CardContent, Grid } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Impostazioni() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
     <Grid container padding={2}>
         <Grid item lg={12}>

             <Card>
                 <CardContent>
                     <Box
                         sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                     >
                         <Tabs
                             orientation="vertical"
                             variant="scrollable"
                             value={value}
                             onChange={handleChange}
                             aria-label="Vertical tabs example"
                             sx={{ borderRight: 1, borderColor: 'divider' }}
                         >
                             <Tab label="Sezione 1" {...a11yProps(0)} />
                             <Tab label="Sezione 2" {...a11yProps(1)} />
                             <Tab label="Sezione 3" {...a11yProps(2)} />
                             {/* Aggiungi altre schede se necessario */}
                         </Tabs>
                         <TabPanel value={value} index={0}>
                             Contenuto Sezione 1
                         </TabPanel>
                         <TabPanel value={value} index={1}>
                             Contenuto Sezione 2
                         </TabPanel>
                         <TabPanel value={value} index={2}>
                             Contenuto Sezione 3
                         </TabPanel>
                         {/* Aggiungi altri pannelli TabPanel se necessario */}
                     </Box>
                 </CardContent>
             </Card>
         </Grid>
     </Grid>
    );
}

export default Impostazioni;
