import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Grid,
    InputLabel,
    Card,
    CardContent,
    TextField,
    Typography,
    Modal,
} from '@mui/material';
import { useGoogleMaps } from '../../components/layout/GoogleMapsContext';

const containerStyle = {
    width: '400px',
    height: '400px',
};

const defaultCenter = {
    lat: -3.745,
    lng: -38.523,
};

const googleLibraries = ['places', 'geometry'];


function Address({ field, value, onChange, isMap = false }) {

    const { isLoaded, loadError } = useGoogleMaps();

    const [address, setAddress] = useState('');

    const [addressDetails, setAddressDetails] = useState({
        address: '',
        city: '',
        region: '',
        country: '',
        postalCode: '',
        latitude: null,
        longitude: null,
        neighborhood: '',
        province: '',
    });
    const [center, setCenter] = useState(defaultCenter);
    const [markerPosition, setMarkerPosition] = useState(null);


    const autocompleteInputRef = useRef(null);

    useEffect(() => {
        if (!isLoaded || !value) return;

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: value }, (results, status) => {
            if (status === 'OK' && results.length > 0) {
                const place = results[0];
                setAddressDetailsFromPlace(place);
            } else {
                console.error(`Geocode was not successful for the following reason: ${status}`);
            }
        });
    }, [value, isLoaded]); // Ensure this effect runs when either 'value' or 'isLoaded' changes.



    const setAddressDetailsFromPlace = (place) => {
        const address = place.formatted_address;
        let city, region, country, postalCode, latitude, longitude, neighborhood, province;

        place.address_components.forEach(component => {
            const componentType = component.types[0];

            switch (componentType) {
                case "locality":
                    city = component.long_name;
                    break;
                case "administrative_area_level_1":
                    region = component.long_name;
                    break;
                case "country":
                    country = component.long_name;
                    break;
                case "postal_code":
                    postalCode = component.long_name;
                    break;
                case "sublocality":
                case "sublocality_level_1":
                    neighborhood = component.long_name;
                    break;
                case "administrative_area_level_2":
                    province = component.long_name;
                    break;
                default:
                    break;
            }
        });

        const location = place.geometry.location;
        latitude = location.lat();
        longitude = location.lng();

        setCenter({ lat: latitude, lng: longitude });
        setMarkerPosition({ lat: latitude, lng: longitude });

        setAddressDetails({ address, city, region, country, postalCode, latitude, longitude, neighborhood, province })
        // Invia i dettagli all'esterno tramite la funzione onChange
        onChange(field.fieldName, address);
        onChange('city', city);
        onChange('region', region);
        onChange('country', country);
        onChange('postalCode', postalCode);
        onChange('latitude', latitude.toString());
        onChange('longitude', longitude.toString());
        onChange('neighborhood', neighborhood);
        onChange('province', province);
    };


    useEffect(() => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setCenter(pos);
                setMarkerPosition(pos);
                // Potresti voler fare una geocodifica inversa qui per ottenere l'indirizzo della posizione corrente
            }, () => {
                console.log("The Geolocation service failed.");
            });
        } else {
            // Browser doesn't support Geolocation
            console.log("Your browser doesn't support geolocation.");
        }



        if (isLoaded && autocompleteInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInputRef.current);
            autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'name']);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                const address = place.formatted_address;
                let city, region, country, postalCode, latitude, longitude, neighborhood, province;

                place.address_components.forEach(component => {
                    console.log('component', component)
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1")) {
                        region = component.long_name;
                    } else if (component.types.includes("country")) {
                        country = component.long_name;
                    } else if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    } else if (component.types.includes("sublocality") || component.types.includes("sublocality_level_1")) {
                        neighborhood = component.long_name;
                    } else if (component.types.includes("administrative_area_level_2")) {
                        province = component.long_name;
                    }
                });


                if (place.geometry && place.geometry.location) {
                    latitude = place.geometry.location.lat();
                    longitude = place.geometry.location.lng();
                }


                setAddressDetails({ address, city, region, country, postalCode, neighborhood, province });
                setAddress(address);


                onChange(field.fieldName, address);
                onChange('city', city);
                onChange('region', region);
                onChange('country', country);
                onChange('postalCode', postalCode);
                onChange('latitude', latitude);
                onChange('longitude', longitude);

                const location = place.geometry.location;
                setCenter({
                    lat: location.lat(),
                    lng: location.lng(),
                });
                setMarkerPosition({
                    lat: location.lat(),
                    lng: location.lng(),
                });

                // Qui puoi anche aggiornare gli input con i dettagli del luogo
                // ad esempio: setAddress(place.formatted_address);
            });
        }
    }, [isLoaded]);

    const onMarkerDragEnd = async (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        const geocoder = new window.google.maps.Geocoder();

        try {
            const geocodeData = await geocoder.geocode({ location: { lat: newLat, lng: newLng } });
            if (geocodeData.results[0]) {
                const address = geocodeData.results[0].formatted_address;
                let city, region, country, postalCode, neighborhood, province;

                geocodeData.results[0].address_components.forEach(component => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1")) {
                        region = component.long_name;
                    } else if (component.types.includes("country")) {
                        country = component.long_name;
                    } else if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    } else if (component.types.includes("sublocality") || component.types.includes("sublocality_level_1")) {
                        neighborhood = component.long_name;
                    } else if (component.types.includes("administrative_area_level_2")) {
                        province = component.long_name;
                    }
                });

                // Aggiorna i dettagli dell'indirizzo e lo stato del componente
                const newAddressDetails = {
                    address, city, region, country, postalCode, latitude: newLat, longitude: newLng, neighborhood,
                    province
                };
                setAddressDetails(newAddressDetails);

                // Invoca onChange per ogni dettaglio aggiornato

                onChange(field.fieldName, newAddressDetails.address);
                onChange('city', newAddressDetails.city);
                onChange('region', newAddressDetails.region);
                onChange('country', newAddressDetails.country);
                onChange('postalCode', newAddressDetails.postalCode);
                onChange('latitude', newAddressDetails.latitude);
                onChange('longitude', newAddressDetails.longitude);

                setCenter({ lat: newLat, lng: newLng });
                setMarkerPosition({ lat: newLat, lng: newLng });
            } else {
                console.log("No results found");
            }
        } catch (error) {
            console.error('Error retrieving address details:', error);
        }
    };


    const handleInputChange = (fieldName) => {
        // Aggiorna lo stato o esegui altre azioni qui

    };

    const styleInput = {
        width: "100%",
        borderRadius: "2px",
        border: "1px solid #c4c4c4",
        padding: "2px 5px"
    }


    return (
        <div>


            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        margin='normal'
                        variant='outlined'
                        sx={{ height: "50px", zIndex: 1400 }}
                        type="text"
                        name={field.fieldName}
                        fullWidth
                        placeholder="Cerca un indirizzo"
                        defaultValue={value}
                        onChange={(e) => setAddress(e.target.value)}
                        InputProps={{
                            inputRef: autocompleteInputRef, // Usa InputProps per passare la ref
                            style: { zIndex: 1400 } // Questo potrebbe non funzionare se applicato qui, dipende dalla struttura del DOM

                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel sx={{ fontSize: 14 }}>Città</InputLabel>
                    <input type="text" style={styleInput} value={addressDetails.city} readOnly />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel sx={{ fontSize: 14 }}>CAP</InputLabel>
                    <input type="text" style={styleInput} value={addressDetails.postalCode} readOnly />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel sx={{ fontSize: 14 }}>Regione</InputLabel>
                    <input type="text" style={styleInput} value={addressDetails.region} readOnly />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel sx={{ fontSize: 14 }}>Nazione</InputLabel>
                    <input type="text" style={styleInput} value={addressDetails.country} readOnly />
                </Grid>
            </Grid>

            {isMap && isLoaded && (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                >
                    {markerPosition && <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                    />}
                </GoogleMap>
            )}
        </div>
    );
}

export default React.memo(Address);
