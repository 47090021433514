import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxCST = ({ field, value, onChange, fieldErrors }) => {
    const fieldRef = useRef(null);

    useEffect(() => {
        if (fieldErrors && fieldRef.current) {
            fieldRef.current.style.border = '2px solid red';
        } else if (fieldRef.current) {
            fieldRef.current.style.border = '';
        }
    }, [fieldErrors]);

    const handleCheckboxChange = (e) => {
        onChange(field.fieldName, e.target.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChange}
                    name={field.fieldName}
                    ref={fieldRef}
                />
            }
            label={field.label}
        />
    );
};

export default CheckboxCST;

