import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

const String = ({ field, value, onChange, fieldErrors }) => {
  const fieldRef = useRef(null);

  // Aggiorna lo stile del campo in caso di errore
  useEffect(() => {
    if (fieldErrors && fieldRef.current) {
      fieldRef.current.querySelector('input').style.border = '2px solid red';
    } else if (fieldRef.current) {
      fieldRef.current.querySelector('input').style.border = '';
    }
  }, [fieldErrors]);

  // Gestione del cambiamento del valore del campo
  const handleInputChange = (e) => {

    onChange(field.fieldName, e.target.value);
  };

  return (
    <TextField
      sx={{ height: "20px" }}
      defaultValue={value || ''}
      type="text"
      margin='normal'
      variant='outlined'
      id={field.fieldName}
      name={field.fieldName}
      fullWidth
      onChange={handleInputChange}
      ref={fieldRef}
    // Aggiungi altre props necessarie
    />
  );
};

export default String;
