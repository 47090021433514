
import React, { useState } from "react";
import { Box, Container, CssBaseline, Fab } from "@mui/material";
import KanbanIcon from "@mui/icons-material/ViewAgenda";
import ListIcon from "@mui/icons-material/List";
import GanttCST from 'components/layout/Gantt';
import List from "pages/Adminlist/List";

const PageGantt = () => {
    const [viewMode, setViewMode] = useState("list");

    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === "list" ? "kanban" : "list"));
    };

    return (
        <div
            component="main"
            maxWidth="xl"
            sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
            <CssBaseline />
            <GanttCST />
        </div>
    );
};

export default PageGantt;
;