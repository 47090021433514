import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
 
import {
    Unstable_Grid2 as Grid,
    TextField,
    IconButton
} from '@mui/material';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import VisibilityIcon from '@mui/icons-material/Visibility'; 
//import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import { truncate } from 'lodash';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const GanttCST = () => {
    const [view, setView] = useState(ViewMode.Day)

    let tasks: Task[] = [
        {
            start: new Date(2020, 1, 1),
            end: new Date(2020, 1, 2),
            name: 'Idea',
            id: 'IdeaProject',
            type:'project',
            progress: 45,
            hideChildren: false,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
        },
        {
            start: new Date(2020, 1, 1),
            end: new Date(2020, 1, 2),
            name: 'Idea task 1',
            id: 'Task 1',
            type:'task',
            project: 'IdeaProject',
            progress: 75,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
        },
    ];


    function getStartEndDateForProject(tasks, projectId) {
        const projectTasks = tasks.filter((t) => t.project === projectId);

        let start = projectTasks[0].start;
        let end = projectTasks[0].end;
        for (let i = 0; i < projectTasks.length; i++) {
            const task = projectTasks[i];
            if (start.getTime() > task.start.getTime()) {
                start = task.start;
            }
            if (end.getTime() < task.end.getTime()) {
                end = task.end;
            }
        }
        return [start, end];
    }

    const [allTask, setTasks] = useState(tasks)
    const [isChecked, setIsChecked] = useState(true)
    let columnWidth = 60
    if (view === ViewMode.Month) {
        columnWidth = 300
    } else if (view === ViewMode.Week) {
        columnWidth = 250
    }

    const handleTaskChange = task => {
        /* console.log("On date change Id:" + task.id) */
        let newTasks = tasks.map(t => (t.id === task.id ? task : t))
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project)
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)]
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end }
                newTasks = newTasks.map(t => (t.id === task.project ? changedProject : t))
            }
        }
        setTasks(newTasks)
    }

    const handleTaskDelete = task => {
        const conf = window.confirm("Are you sure about " + task.name + " ?")
        if (conf) {
            setTasks(tasks.filter(t => t.id !== task.id))
        }
        return conf
    }

    const handleProgressChange = async task => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)))
        /* console.log("On progress change Id:" + task.id) */
    }

    const handleDblClick = task => {
        alert("On Double Click event Id:" + task.id)
    }

    const handleSelect = (task, isSelected) => {
        /* console.log(task.name + " has " + (isSelected ? "selected" : "unselected")) */
    }

    const handleExpanderClick = task => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)))
        /* console.log("On expander click Id:" + task.id) */
    }



    return (
        <>
            <Box sx={{ '& button': { m: 1 } }}>
                <div className="ViewContainer">
                    <Button size="small" variant="outlined" onClick={() => setView(ViewMode.QuarterDay)}>Un quarto di giorno</Button>
                    <Button size="small" variant="outlined" onClick={() => setView(ViewMode.HalfDay)}>Mezza giornata</Button>
                    <Button size="small" variant="outlined" onClick={() => setView(ViewMode.Day)}>Giorno</Button>
                    <Button size="small" variant="outlined" onClick={() => setView(ViewMode.Week)}>Settimana</Button>
                    <Button size="small" variant="outlined" onClick={() => setView(ViewMode.Month)}>Mese</Button>
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={isChecked} onClick={() => isChecked == true ? setIsChecked(false) : setIsChecked(true)} />}
                        label="Vedi task"
                    />
                </div>
            </Box>

            <div>
                <Gantt
                    tasks={allTask}
                    viewMode={view}
                    onDateChange={handleTaskChange}
                    onDelete={handleTaskDelete}
                    onProgressChange={handleProgressChange}
                    onDoubleClick={handleDblClick}
                    onSelect={handleSelect}
                    onExpanderClick={handleExpanderClick}
                    listCellWidth={isChecked ? "155px" : ""}
                    columnWidth={columnWidth}
                    locale={'it-IT'}
                />
            </div>
        </>
    );
};

export default GanttCST;